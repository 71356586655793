import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as  Router, Route, Routes} from 'react-router-dom';
import App from './App';
import Index from './views/cms/page/menu/Index';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>
<Router>
    <App />
</Router>






    </>
);


