import React, { useEffect, useState } from 'react'
import { Col, NavLink, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { allblogpage } from '../apiservice/Service.api'
import Index from '../breadcrumb/Index'
import MyHelmet from '../helmet/MyHelmet'
import Nodata from '../nodata/Nodata'
const Blogs = () => {
  const navigate = useNavigate();


  const [allblogpageData, setallblogpageData] = useState([])

  const allblogpageApi =  async () =>{
    let res = await allblogpage();
    setallblogpageData(res.data);
    console.log(res.data , 'allblog page')
  }
  useEffect(()=>{
    allblogpageApi();
  },[])




  return (<>
  

  <MyHelmet meta_title='All Blogs'/>

  <Index page='Blogs' />
    <div className="about-wrapper blogs-wrapper">
    <div className="about-title-box">
      <h2 className='mb-5'>Latest Blogs</h2>
    </div>
    
    <Row>
      <Col md={8} className='blog-col-1' >

      {
        allblogpageData.length ?
        allblogpageData?.map((val)=>{
          return(<>
            <div className="card-body blog-card-wrapper mb-5">
          <div className="imgBox">
            <img src={val.image} alt="" />
          </div>
          <div className="para-box">
            <ul className="list-unstyled">
              <li className='d-flex justify-content-between flex-wrap'> <span className='publish-by'>By {val.posteBy[0].name} / {val.createdAt.slice(0,10)} {val.createdAt.slice(11,16)} / {val.category}</span> <span className='heart-tag'><i class="fa-regular fa-heart"></i> <i class="fa-solid fa-heart active"></i><small>{val.likes}</small></span> </li>
              <hr />
              <li> <h2 className='blog-title tx-ep-3'>{val.title}</h2> </li>
              <li> <p className='blog-para  tx-ep-3' dangerouslySetInnerHTML={{ __html:val.discription }}></p> </li>
              <li>  <div className="btn-box">
              <button onClick={()=>{navigate(`/blogs-details/${val._id}`)}} >Read More</button>
     </div></li>
            </ul>
          </div>
        </div>
          </>)
        })

        :
        <Nodata />
      }
        

      </Col>
      <Col md={4} className='blog-col-2' >
      <div className="search-box-wrapper mb-3">
      <label htmlFor="" className="label-tag">Search</label>
        <div className="search-box">
        <input type="text" className='' />
        <button>Search</button>
        </div>
      </div>
      <div className="recent-box-wrapper mb-3">
      <label htmlFor="" className="label-tag">Recent Activity</label>
        <ul className="list-unstyled">
          <li> <NavLink to='/' className='blog-link' >Hello World!</NavLink> </li>
          <li> <NavLink to='/' className='blog-link' >Best Ways To Make Your Mobile Website Load Faster</NavLink> </li>
          <li> <NavLink to='/' className='blog-link' >What To Ask When Choosing A Web Designer In Sydney</NavLink> </li>
          <li> <NavLink to='/' className='blog-link' >Skills You Need For Efficient Design</NavLink> </li>
          <li> <NavLink to='/' className='blog-link' >Hello World!</NavLink> </li>
        </ul>
      </div>

      </Col>
    
    </Row>
    
    </div>
  
  
  </>)
}

export default Blogs