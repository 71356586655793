import axios from "axios"

const currentURL = window.location.href 
const currentURL2 = window.location.pathname 
let baseurl = currentURL.split(currentURL2)

export let autoconfig = {

  headers : {
    'user-id' : process.env.REACT_APP_ACTKEY,
    'base-url' : baseurl[0],
    'user-name' : process.env.REACT_APP_USERNAME,
  }
}


export const statsPut = async () =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/stats`,{
            userid : process.env.REACT_APP_ACTKEY
        } , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}


export const homepage = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/home` , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const aboutpage = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/about-page` , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}


export const servicepage = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/service-page` , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}


export const portfoliopage = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/portfolio-page` , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const blogpage = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/blog-page` , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const allblogpage = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/all-blogs-public` , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const blogsinglepage = async (bid) =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/blogs/${bid}` , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const blogpostlike = async (bid) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/blogs-like/${bid} ` , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const blogcommentpage = async (collectdata,bid) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/blogs-add-comment`,{
            name : collectdata.name,
            email : collectdata.email,
            comment : collectdata.comment,
            blogID : bid
        } , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const contactpage = async (collectdata) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/contact`,collectdata , autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const sitesettings = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/settings-public`, autoconfig)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}