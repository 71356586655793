import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { AccountApi, AccountUpdateApi } from '../../api/Service.dashboard'
import { SweetAlert } from '../alert/SweetAlert';

const AdminProfile = () => {


             /* api call */
             const [AccountData, setAccountData] = useState([]);
             const apiAcconut =  async () =>{
              let res = await AccountApi();
              setAccountData(res.data[0])
             }
             useEffect(()=>{
               apiAcconut();
             },[])


             

  const [CollectData , setCollectData] = useState({
            name : "",
            password : "",
            cpassword : "",
            dob : "",
            image : "",
            phone : "",
            country : "",
            freelancer  : "",
            degination  : "",
  })

  const handelAcclInp = (e) =>{
    let type = e.target.type ;
    let name = e.target.name ;
    let value;
    if(type==='file'){
      value = e.target.files[0] ;
    }
    else{
      value = e.target.value;
    }
    setCollectData({...CollectData , [name] : value});
  }

  const [profileImg , setprofileImg] = useState()


  const apiAccPost =  async (e) =>{
    e.preventDefault();
    let res = await AccountUpdateApi(CollectData);
    if(res.status === true){
      SweetAlert({title:'Account Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
      }
      apiAcconut();
   }


  return (<>
  
   <Container fluid>
   <Row className='card-body-w box-s'>
       <Form className='row' onSubmit={apiAccPost}>
       <Col sm={12}>
      <div className="profile-box-image">
      <img src={AccountData.image} alt="profile-image" className='rounded-circle my-acc-pp' />
      {AccountData.verified ===true ? <img src="/images/verify.png" alt="" className='badge-tag'  /> : null} 
      </div>
       
        <Col md={6} className='my-2'>
          <Form.Group>
          <Form.Label>Upload Profile Image</Form.Label>
          <Form.Control type='file' name='image' onChange={handelAcclInp} />
          </Form.Group>
        </Col>
       </Col>
       <Col md={6} className='my-2'>
          <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control type='text' defaultValue={AccountData.name} name='name' onChange={handelAcclInp} />
          </Form.Group>
        </Col>
        <Col md={6} className='my-2'>
          <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control type='text' disabled defaultValue={AccountData.email} name='email' onChange={handelAcclInp} />
          </Form.Group>
        </Col>
        <Col md={6} className='my-2'>
          <Form.Group>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control type='text' defaultValue={AccountData.phone} name='phone' onChange={handelAcclInp} />
          </Form.Group>
        </Col>


        <Col md={6} className='my-2'>
          <Form.Group>
          <Form.Label>DOB</Form.Label>
          <Form.Control type='date' defaultValue={AccountData.dob} name='dob' onChange={handelAcclInp} />
          </Form.Group>
        </Col>
        <Col md={6} className='my-2'>
          <Form.Group>
          <Form.Label>Country</Form.Label>
          <Form.Control type='text' defaultValue={AccountData.country} name='country' onChange={handelAcclInp} />
          </Form.Group>
        </Col>
        <Col md={6} className='my-2'>
          <Form.Group>
          <Form.Label>Degination</Form.Label>
          <Form.Control type='text' defaultValue={AccountData.degination} name='degination' onChange={handelAcclInp} />
          </Form.Group>
        </Col>
        <Col md={6} className='my-2'>
          <Form.Group>
          <Form.Label>Freelancer</Form.Label>
          <Form.Select name='freelancer' onChange={handelAcclInp} >
          <option>--select--</option> 

          {
            AccountData.freelancer === true ?  <option value={true} selected >Available</option> 
            :  <option value={true}  >Available</option> 
          
          }

          
          {
            AccountData.freelancer === false ?  <option value={false} selected >No</option> 
            :  <option value={false}  >No</option> 
          
          }
         

          </Form.Select>
          </Form.Group>
        </Col>

        {/* <Col md={6} className='my-2'>
          <Form.Group>
          <Form.Label>Upload Resume</Form.Label>
          <Form.Control type='file' name='cv' onChange={handelAcclInp} />
          </Form.Group>
        </Col> */}

        <Col xs={12} className='my-4'>
          <Form.Group>
          <Button className='btn-cms' type='submit'>Update</Button>
          </Form.Group>
        </Col>
       </Form>

      </Row>
   </Container>
  
  
  </>)
}

export default AdminProfile