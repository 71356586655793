import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { AccountUpdateApi, ResetPassApi } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';

const AdminResetPass = () => {

  const [CollectData , setCollectData] = useState({
    password : "",
    cpassword : "",
})

const handelAcclInp = (e) =>{
let type = e.target.type ;
let name = e.target.name ;
let value;
if(type==='file'){
value = e.target.files[0] ;
}
else{
value = e.target.value;
}
setCollectData({...CollectData , [name] : value});
}


const apiAccPost =  async (e) =>{
try{
  console.log('clicked')
e.preventDefault();
let res = await AccountUpdateApi(CollectData);
if(res.status === true){
SweetAlert({title:'Password Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
}
}

catch(err){
  SweetAlert({title:'Password Updation' , msg : "failed" , icon : 'warning', btn : 'close', dangerMode: true})
  }

}

  return (<>
  
 <Container>
  <Row>
    <Col md={6} className='card-body-w box-s m-auto'  >
      <Form onSubmit={apiAccPost}>
        {/* <Form.Group className='my-3'>
          <Form.Label>Current Password</Form.Label>
          <Form.Control type='text' />
        </Form.Group> */}

        <Form.Group className='my-3'>
          <Form.Label>New Password</Form.Label>
          <Form.Control type='text' name='password' onChange={handelAcclInp} />
        </Form.Group>
        <Form.Group className='my-3'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control type='text' name='cpassword' onChange={handelAcclInp} />
        </Form.Group>

        <Form.Group className='my-3'>
        <Button className='btn-cms' type='submit'>Save</Button>
          
        </Form.Group>

      </Form>
    </Col>
  </Row>
 </Container>
  
  
  </>)
}

export default AdminResetPass