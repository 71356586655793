import React from 'react'

const Nodata = () => {
  return (
    <>
        <div className="nodata-box">
            <p>No Data Avaiable!</p>
        </div>
    </>
  )
}

export default Nodata