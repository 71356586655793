import React from "react";
import AnimatedCursor from "react-animated-cursor"

const Cursor = () => {
  return (
    <>

        <div className="App">
    <AnimatedCursor
      innerSize={10}
      outerSize={24}
      color='204,204,204'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
    />
    </div>
    </>
  )
}

export default Cursor