import React, { useEffect } from 'react'
import { useState } from 'react';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { devtoolGetApi, devtoolPutApi } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
const AdminDeveloper = () => {

  const [DevProps,setDevProps] = useState({
    'web_theme_pri': '',
    'web_theme_sec': '',
    'cms_theme_pri': '',
    'cms_theme_sec': '',
    'fontfamily': '',

    'btnpadx' : '',
    'btnpady' : '',
    'btnbg' : '',
    'btntx' : '',
    'btnhovbg' : '',
    'btnhovtx' : '',
    'btnsd1' : '',
    'btnsd2' : '',
    'btnsd3' : '',
    'btnsd4' : '',
    'btnradi' : '',
    'btnbd' : ''

  })

  const handelBtnInp = (e) =>{
    let type = e.target.type ;
    let name = e.target.name ;
    let value = e.target.value;
    setDevProps({...DevProps , [name] : value});
  }

  
             /* api call */
             const [DevToolData, setDevToolData] = useState([]);
             const apiDevToolGet =  async () =>{
              let res = await devtoolGetApi();
              setDevToolData(res.data)
              console.log(res.data , 'DevToolDataDevToolSetData')
             }
             useEffect(()=>{
               apiDevToolGet();
             },[])

             const apiDevToolPut =  async (e) =>{
              e.preventDefault();
              let res = await devtoolPutApi(DevProps);
              setDevToolData(res.data);

              if(res.status === true){
                SweetAlert({title:'Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
                }

             }
             

             const [customeCss , setcustomeCss] = useState()
             function onChange(newValue) {
              console.log(newValue);
              setcustomeCss(newValue)
            }

  // const cmsfn = () =>{

  //   console.log(DevProps , 'DevPropsDevProps')
  // }
  return (<>

  <style>
    {customeCss}
  </style>
  
<Container className='cms-devloper-container card-body-w box-s'>
  <Row>
    {/* <Col md={6}>
      <div className="">
            <p className="title-tag">Button Customization</p>
            <button style={{"borderRadius" : borderRadius,"padding" : `${padX} ${padY}` , 'backgroundColor' : bg}}>Demo</button>

            <Form.Label>Range</Form.Label>
          <Form.Range onChange={(e)=>{runSlider(e)}} defaultValue={5} />
          <Form.Control type="color" onChange={(e)=>{runColor(e)}} defaultValue={5} />
          <Form.Control type="text" placeholder='paddingX' onChange={(e)=>{runPadX(e)}} defaultValue={5} />
          <Form.Control type="text" placeholder='paddingY' onChange={(e)=>{runPadY(e)}} defaultValue={5} />



      </div>
    </Col> */}

    <Col sm={12}>
      <Form className='row' onSubmit={apiDevToolPut}> 

      <Col lg={6} >
        <Form.Group className=''>
        <p className="title-tag">Website Theme Color <Badge bg="secondary">2</Badge></p>
        <div className="">
        <div  className="mb-3 div d-flex justify-content-between" style={{'width' : '300px'}}>
         <Form.Label className='my-auto me-3'>Primary Color </Form.Label>
          <Form.Control type='color' name='web_theme_pri' onChange={handelBtnInp}  defaultValue={DevToolData.web_theme_pri}  />
         </div>

         <div  className="mb-3 div d-flex justify-content-between" style={{'width' : '300px'}}>
         <Form.Label className='my-auto me-3'>Secondary Color </Form.Label>
          <Form.Control type='color' name='web_theme_sec' onChange={handelBtnInp}  defaultValue={DevToolData.web_theme_sec}  />
         </div>
        </div>

        </Form.Group>
      </Col>

      <Col lg={6} >
        <Form.Group className=''>
        <p className="title-tag">CMS Theme Color <Badge bg="secondary">2</Badge></p>
        <div className="">
        <div  className="mb-3 div d-flex justify-content-between" style={{'width' : '300px'}}>
         <Form.Label className='my-auto me-3'>Primary Color </Form.Label>
          <Form.Control type='color' name='cms_theme_pri' onChange={handelBtnInp} defaultValue={DevToolData.cms_theme_pri} />
         </div>

         <div  className="mb-3 div d-flex justify-content-between" style={{'width' : '300px'}}>
         <Form.Label className='my-auto me-3'>Secondary Color </Form.Label>
          <Form.Control type='color' name='cms_theme_sec' onChange={handelBtnInp} defaultValue={DevToolData.cms_theme_sec} />
         </div>
        </div>

        </Form.Group>
      </Col>


      <Col lg={6} >
        <Form.Group>
        <p className="title-tag">Font Family <Badge bg="secondary">15</Badge></p>
     
        <div  className="mb-3 div d-flex flex-column justify-content-between" style={{'width' : '300px'}}>
         <Form.Label className='my-auto me-3'>Font Family </Form.Label>
         <select className='form-select' name='fontfamily' onChange={handelBtnInp}>
  
        <option value="Times New Roman"  selected="selected"> Times New Roman </option>
        <option value="Arial">Arial</option>
        <option value="fantasy">Fantasy</option>
        <option value="cursive">cursive</option>
    </select>
         </div>
        </Form.Group>
      </Col>

{/* 
      <Col lg={12} >
        <Form.Group>
        <p className="title-tag">Buttons <Badge bg="secondary">15</Badge></p>
     
        <div  className="mb-3 div d-flex flex-column justify-content-between" style={{'width' : '300px'}}>
         <Form.Label className='my-auto me-3'>Button </Form.Label>
         <select className='form-select'>
  
        <option value="Times New Roman" 
            selected="selected">
            Button 1
        </option>
        <option value="Arial">Button 2</option>
        <option value="fantasy">Button 3</option>
        <option value="cursive">Button 4</option>
    </select>
         </div>


         <div className="button-group">
          <Button className='btn-gp btn-gp-1 me-2'>Click Me</Button>
          <Button className='btn-gp btn-gp-2 me-2'>Click Me</Button>
          <Button className='btn-gp btn-gp-3 me-2'>Click Me</Button>
          <Button className='btn-gp btn-gp-4 me-2'>Click Me</Button>
          <Button className='btn-gp btn-gp-5 me-2'>Click Me</Button>
          <Button className='btn-gp btn-gp-6 me-2'>Click Me</Button>
          <Button className='btn-gp btn-gp-7 me-2'>Click Me</Button>
          <Button className='btn-gp btn-gp-8 me-2'>Click Me</Button>

         </div>

        </Form.Group>
      </Col> */}


          <Col md={12}>
      <div className="">
            <p className="title-tag">Button Customization <Badge bg="secondary">1</Badge></p>
            <button style={{"borderRadius" : `${DevProps.btnradi}px`,"padding" : `${DevProps.btnpadx}px ${DevProps.btnpady}px` , 'backgroundColor' : `${DevProps.btnbg}` , color:`${DevProps.btntx}` , border :`0px solid black` , boxShadow: `${DevProps.btnsd1}px ${DevProps.btnsd2}px ${DevProps.btnsd3}px ${DevProps.btnsd4} `}}>Demo</button>


          <div className="my-3 div d-flex flex-column justify-content-between" style={{'width' : '350px'}}>
          <small className='my-auto'>Border Radius</small>
          <Form.Range name='btnradi' onChange={handelBtnInp} defaultValue={DevToolData.btnradi} max={25}  style={{'width' : '350px'}} />
          </div>

          <div className="mb-3 div d-flex justify-content-between" style={{'width' : '350px'}}>
          <small className='my-auto'>Background Color</small>
          <Form.Control name='btnbg' type="color" onChange={handelBtnInp} defaultValue={DevToolData.btnbg} />
          </div>

          <div className="mb-3 div d-flex justify-content-between" style={{'width' : '350px'}}>
          <small className='my-auto'>Text Color</small>
          <Form.Control name='btntx' type="color" onChange={handelBtnInp} defaultValue={5} />
          </div>

          <div className="mb-3 div d-flex justify-content-between" style={{'width' : '350px'}}>
          <small className='my-auto'>Padding-Y</small>
          <Form.Control name='btnpady' style={{'width' : '100px'}} type="text" placeholder='--px' onChange={handelBtnInp} defaultValue={DevToolData.btnpady} />
          </div>

          <div className="mb-3 div d-flex justify-content-between" style={{'width' : '350px'}}>
          <small className='my-auto'>Padding-X</small>
          <Form.Control name='btnpadx' style={{'width' : '100px'}} type="text" placeholder='--px' onChange={handelBtnInp} defaultValue={DevToolData.btnpadx} />
          </div>



          <div className="mb-3 div d-flex justify-content-between" style={{'width' : '350px'}}>
          <small className='my-auto'>Border</small>
          <Form.Control name='btnbd' style={{'width' : '100px'}} type="text" placeholder='--px' onChange={handelBtnInp} defaultValue={0} />
          </div>

          <div className="mb-3 div d-flex justify-content-between" style={{'width' : '350px'}}>
          <small className='my-auto'>Box Shadow</small>
          <Form.Control name='btnsd1' style={{'width' : '50px'}} type="text" placeholder='--px' onChange={handelBtnInp} defaultValue={DevToolData.btnsd1} />
          <Form.Control name='btnsd2' style={{'width' : '50px'}} type="text" placeholder='--px' onChange={handelBtnInp} defaultValue={DevToolData.btnsd2} />
          <Form.Control name='btnsd3' style={{'width' : '50px'}} type="text" placeholder='--px' onChange={handelBtnInp} defaultValue={DevToolData.btnsd3} />
          <Form.Control name='btnsd4' style={{'width' : '50px'}} type="color" placeholder='--px' onChange={handelBtnInp} defaultValue={DevToolData.btnsd4} />
          </div>


          <div className="mb-3 div d-flex justify-content-between" style={{'width' : '350px'}}>
          <small className='my-auto'>Hover Background Color</small>
          <Form.Control name='btnhovbg' type="color" onChange={handelBtnInp} defaultValue={DevToolData.btnhovbg} />
          </div>

          <div className="mb-3 div d-flex justify-content-between" style={{'width' : '350px'}}>
          <small className='my-auto'>Hover Text Color</small>
          <Form.Control name='btnhovtx' type="color" onChange={handelBtnInp} defaultValue={DevToolData.btnhovtx} />
          </div>
      </div>
    </Col>
    

    <Col>
    <p className='my-2'>Custom css</p>
    <AceEditor
    mode="css"
    theme="github"
    onChange={onChange}
    name="UNIQUE_ID_OF_DIV"
    editorProps={{ $blockScrolling: true }}
    placeholder="write your custome css"
    enableSnippets={true}
    showGutter={true}
    enableLiveAutocompletion={true}
    enableBasicAutocompletion={true}
    cursorStart={1}
  />
    </Col>


      <Form.Group>
      <Button type='submit' className='btn-cms my-5' style={{'width' : '150px'}}>Submit</Button>
      </Form.Group>
      </Form>

    </Col>
  </Row>


</Container>
  
  
  </>)
}

export default AdminDeveloper