import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Index from '../breadcrumb/Index'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { aboutpage } from '../apiservice/Service.api';
import MyHelmet from '../helmet/MyHelmet';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const About = () => {

  const [aboutpageData, setaboutpageData] = useState([])

  const [skills, setskills] = useState([])
  const [eduexp, seteduexp] = useState([])

  const [profile, setprofile] = useState([])

  const [dob2, setdob] = useState([])



  const aboutpageApi =  async () =>{
    let res = await aboutpage();
    setaboutpageData(res.data[0]);
    setskills(res.data[0].skills_total)
    seteduexp(res.data[0].eduexp_total)
    setprofile(res.data[0].profile_data[0])
    setdob(res.data[0].profile_data[0].dob.slice(0,4))


    console.log(res.data[0] , 'about page')



  }
  useEffect(()=>{
    aboutpageApi();
  },[])

  let datenow = new Date().getFullYear();
  console.log(datenow)
  let age =   datenow - dob2
  console.log(age , 'age')
  console.log(skills , 'skillsskills')
 


    let programmer_skill = skills.filter(val=>{
        return val.skill_type === "programming"
      })
    
    let other_skill =  skills.filter(val=>{
      return val.skill_type === "otherskill"
    })

    let knowledge =  skills.filter(val=>{
      return val.skill_type === "knowledge"
    })

    let intrest =  skills.filter(val=>{
      return val.skill_type === "intrest"
    })

    let myedu =  eduexp.filter(val=>{
      return val.type === "education"
    })

    let myexp =  eduexp.filter(val=>{
      return val.type === "experience"
    })

    console.log(myedu,myexp , 'myedu,myexpmyedu,myexp')


  //   console.log(programmer_skill , other_skill)

  // let programmer_skill = aboutpageData.skills_total.filter(val=>{
  //   return val.skill_type === "Programming"
  // })

  // console.log(programmer_skill , 'programmer_skill')
  
  // let Other_skill = aboutpageData.skills_total.filter(val=>{
  //   return val.skill_type === "Other_skill"
  // })

  // console.log(Other_skill , 'Other_skill')

  return ( <>
      <MyHelmet meta_title={aboutpageData.meta_title || 'My About'} meta_description={aboutpageData.meta_description} meta_keyword={aboutpageData.meta_keyword} />
  
 <Index page='About'/>
    <div className="about-wrapper">
    <div className="about-title-box">
      <h2 className='mb-5'>About</h2>
    </div>

    <div className="card-body profile-card-info">
      <div className="imgBox">
        <img src={aboutpageData.profile_image || `/images/noimg.png`  } alt=""/>
      </div>
      <div className="paraBox my-4">
        <ul className="list-unstyled">
          <li>{aboutpageData.name || <Skeleton baseColor="#202020" highlightColor="#444" />}</li>
          <li>{aboutpageData.degination || <Skeleton baseColor="#202020" highlightColor="#444" />}</li>
        </ul>
      </div>
    </div>
    <hr />
    <p className='para-tag'>{aboutpageData.discription || <Skeleton baseColor="#202020" highlightColor="#444" /> }</p>

<hr />

<Row>
<Col sm={6} > 
<ul className="list-unstyled">
  <li><span className="span1">Birthday:</span> <span className="span2">{profile.dob || <Skeleton baseColor="#202020" highlightColor="#444" /> }</span></li>
  <li><span className="span1">Age:</span> <span className="span2">{age || <Skeleton baseColor="#202020" highlightColor="#444" /> }</span></li>
  <li><span className="span1">Country:</span> <span className="span2 text-uppercase">{profile.country || <Skeleton baseColor="#202020" highlightColor="#444" /> }</span></li>
  <li><span className="span1">Email:</span> <span className="span2">{profile.email || <Skeleton baseColor="#202020" highlightColor="#444" /> }</span></li>

</ul>
</Col>

<Col sm={6} > 
<ul className="list-unstyled">
  <li><span className="span1">Freelancer:</span> <span className="span2">{profile.freelancer===true ? 'Yes' : 'No'}</span></li>
  <li><span className="span1">Degination:</span> <span className="span2">{profile.degination || <Skeleton baseColor="#202020" highlightColor="#444" />}</span></li>
  <li><span className="span1">Phone:</span> <span className="span2">{profile.phone || <Skeleton baseColor="#202020" highlightColor="#444" />}</span></li>
</ul>

</Col>
   

<Button className='btn-all my-4'>Download CV</Button> 
  
<hr />

<Col sm={6}  className='my-md-4 my-3 col-half' > 
<h4 className='my-4'><strong className='text-white'>Programming Skills</strong></h4>

{
  programmer_skill.map((val)=>{
    return(<>
          <div className="progress-box">
<small className='d-flex justify-content-between text-white'> <span>{val.title}</span>  <span>{val.percent}%</span> </small>
<ProgressBar animated now={val.percent} variant="white" className='my-3 rounded-0 text-dark' style={{'backgroundColor' : '#222222' , 'height' : '5px'}}  />
</div>
    </>)
  })
}

{/* <div className="progress-box">
<small className='d-flex justify-content-between text-white'> <span>HTML</span>  <span>80%</span> </small>
<ProgressBar animated now={80} variant="white" className='my-3 rounded-0 text-dark' style={{'backgroundColor' : '#222222' , 'height' : '5px'}}  />
</div>
<div className="progress-box">
<small className='d-flex justify-content-between text-white'> <span>CSS</span>  <span>65%</span> </small>
<ProgressBar animated now={65} variant="white" className='my-3 rounded-0 text-dark' style={{'backgroundColor' : '#222222' , 'height' : '5px'}}  />
</div>
<div className="progress-box">
<small className='d-flex justify-content-between text-white'> <span>JS</span>  <span>90%</span> </small>
<ProgressBar animated now={90} variant="white" className='my-3 rounded-0 text-dark' style={{'backgroundColor' : '#222222' , 'height' : '5px'}}  />
</div> */}

</Col>

<Col sm={6}  className='my-md-4 my-3 col-half' > 
<h4 className='my-4'><strong className='text-white'>Other Skills</strong></h4>
{
  other_skill.map((val)=>{
    return(<>
          <div className="progress-box">
<small className='d-flex justify-content-between text-white'> <span>{val.title}</span>  <span>{val.percent}%</span> </small>
<ProgressBar animated now={val.percent} variant="white" className='my-3 rounded-0 text-dark' style={{'backgroundColor' : '#222222' , 'height' : '5px'}}  />
</div>
    </>)
  })

}


</Col>


<Col sm={6}  className='my-md-4 my-3 col-half' > 
<h4 className='mt-5'><strong className='text-white'>Knowledge</strong></h4>
<ul className="list-unstyled skills-list">
{
  knowledge.map((val)=>{
    return(<>
      <li key={val._id}>{val.title}</li> 
    </>)
  })
}
  {/* <li>Bootstrap</li> <li>Angular React</li> <li>Vue</li> <li>Less Gulp</li> <li>Webpack</li> <li>GruntTweenmax</li> <li>GSAP</li> */}
</ul>

</Col>

<Col sm={6}  className='my-md-4 my-3 col-half' > 
<h4 className='mt-5'><strong className='text-white'>Intrest</strong></h4>
<ul className="list-unstyled skills-list">

{
  intrest.map((val)=>{
    return(<>
      <li key={val._id}>{val.title}</li> 
    </>)
  })
}

  {/* <li>Bootstrap</li> <li>Angular React</li> <li>Vue</li> <li>Less Gulp</li> <li>Webpack</li> <li>GruntTweenmax</li> <li>GSAP</li> */}
</ul>


</Col>



<Col sm={6} className='my-md-4 my-3 col-half'> 
<h4 ><strong className='text-white'>Education</strong></h4>
<ul className="list-unstyled timeline-ul">

{
  myedu?.map((val)=>{
    return(<>
      <li className='timeline-ul-li' key={val._id}> <span className="date-tag">{val.start.slice(0,4)}-{val.end.slice(0,4)}</span> <ul className="list-unstyled">
  <li>{val.company}</li>
  <li>{val.degination}</li>
</ul>
<i class="fa-solid fa-circle"></i>
 </li>


    </>)
  })
}
{
  /* <li className='timeline-ul-li'> <span className="date-tag">2014-2016</span> <ul className="list-unstyled">
  <li>Oxford Univercity</li>
  <li>Master Degree</li>
</ul>
<i class="fa-solid fa-circle"></i>
 </li>

<li className='timeline-ul-li'> <span className="date-tag">2014-2016</span> <ul className="list-unstyled">
  <li>Oxford Univercity</li>
  <li>Master Degree</li>
</ul> 
<i class="fa-solid fa-circle"></i>

</li>

<li className='timeline-ul-li'> <span className="date-tag">2014-2016</span> <ul className="list-unstyled">
  <li>Oxford Univercity</li>
  <li>Master Degree</li>
</ul> 
<i class="fa-solid fa-circle"></i>

</li> */
}
</ul>


</Col>


<Col sm={6} className='my-md-4 my-3 col-half'> 
<h4 ><strong className='text-white'>Experience</strong></h4>
<ul className="list-unstyled timeline-ul">

{
  myexp?.map((val)=>{
    return(<>
      <li className='timeline-ul-li' key={val._id}> <span className="date-tag">{val.start.slice(0,4)}-{val.end.slice(0,4)}</span> <ul className="list-unstyled">
  <li>{val.company}</li>
  <li>{val.degination}</li>
</ul>
<i class="fa-solid fa-circle"></i>
 </li>


    </>)
  })
}

{/* <li className='timeline-ul-li'> <span className="date-tag">2014-2016</span> <ul className="list-unstyled">
  <li>Oxford Univercity</li>
  <li>Master Degree</li>
</ul>
<i class="fa-solid fa-circle"></i>
 </li>

<li className='timeline-ul-li'> <span className="date-tag">2014-2016</span> <ul className="list-unstyled">
  <li>Oxford Univercity</li>
  <li>Master Degree</li>
</ul> 
<i class="fa-solid fa-circle"></i>

</li>

<li className='timeline-ul-li'> <span className="date-tag">2014-2016</span> <ul className="list-unstyled">
  <li>Oxford Univercity</li>
  <li>Master Degree</li>
</ul> 
<i class="fa-solid fa-circle"></i>

</li> */}
</ul>


</Col>

</Row>






    </div>
  
  
  </>
  )
}

export default About