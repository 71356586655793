import React from 'react'
import { useNavigate } from 'react-router-dom'

const Index = (props) => {
  let navigate = useNavigate();
  return (
    <div className='breadCrumb-wrapper'>
        <p> <span onClick={()=>{navigate(props.page1link)}}>{props.page}</span> {props.page2 ?  <span><i class="fa-solid fa-angle-right mx-2"></i> {props.page2}</span> : null}</p>
    </div>
  )
}

export default Index