import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import MyHelmet from '../../../pages/helmet/MyHelmet';
import { ForgetPassApi } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';

const ForgetPass = () => {


  const [email,setemail] = useState();

  const foegetFn = async (e) =>{





  try{  console.log('clicked')
    e.preventDefault();
      let res = await ForgetPassApi({email});
      
      if(res.status === true){
        SweetAlert({title:'Reset Link Send' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
      }

      else{
        SweetAlert({title:'Reset Link Send' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true})
      }
    }

      catch(err){
        SweetAlert({title:'Reset Password Status' , msg : 'failed' , icon : 'warning', btn : 'close', dangerMode: true})
       }
  } 


  return (<>
<MyHelmet meta_title="Admin Forget Password"  />
  
 <Container fluid className='log-container'>
  <Row className='p-3 h-100'>

    <div className="log-card d-flex justify-content-center align-items-center">
    <Col md={3} className='card-body-w box-s m-auto'>
      <Form onSubmit={foegetFn}>
        <Form.Group className='my-3 m-auto d-flex justify-content-center'>
          <img src='/images/logo1.png' height='80px' width='250px' className='m-auto' />
        </Form.Group>

        <Form.Group className='my-3  d-flex justify-content-center align-items-center flex-column text-center'>
        <Form.Label > <h4>Hi, Missing something ? </h4> 
        <p>Enter your credentials to continue</p> 
        </Form.Label>
        </Form.Group>
        
        <Form.Group className='form-group my-3 '>
        <Form.Label > Email </Form.Label>
        <Form.Control  type="text" onChange={(e)=>{setemail(e.target.value)}} />
        </Form.Group>
      <Form.Group className="my-4 d-flex justify-content-between">
        <Button className='btn-cms w-100' type='submit'>Rest Password</Button>
      </Form.Group>
      </Form>
        <hr />
      <Form.Group className="my-4 d-flex justify-content-between">
      <Form.Label className='cursor-pointer mx-auto'> <NavLink className='link-tag' to='/register'>Don't have account?</NavLink>  </Form.Label>
      </Form.Group>


     </Col>
    </div>
 
  </Row>
 </Container>
  
  
  </>)
}

export default ForgetPass