import React from 'react'
import { useState } from 'react'
import Lightroom from 'react-lightbox-gallery'
const Gallery = (props) => {

  let [collImg , setCollImg] = useState(props)

  console.log(props.newdata , 'collImgcollImg')
  // console.log(props.newdata[0] , 'props.newdataprops.newdata')
    var images = [
        {
          src: "https://images.unsplash.com/photo-1577279549270-b9e297533cdd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80",
          // desc: 'Person wearing shoes',
          // sub: 'Gift Habeshaw'
        },
        {
          src: "https://images.unsplash.com/photo-1577277625082-36df4915ebeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
          // desc: 'Blonde woman wearing sunglasses smiling at the camera ',
          // sub: 'Dmitriy Frantsev'
        },
        {
            src: "https://images.unsplash.com/photo-1577277625082-36df4915ebeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
            // desc: 'Blonde woman wearing sunglasses smiling at the camera ',
            // sub: 'Dmitriy Frantsev'
          },
          {
            src: "https://images.unsplash.com/photo-1577277625082-36df4915ebeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
            // desc: 'Blonde woman wearing sunglasses smiling at the camera ',
            // sub: 'Dmitriy Frantsev'
          },
          {
            src: "https://images.unsplash.com/photo-1577277625082-36df4915ebeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
            // desc: 'Blonde woman wearing sunglasses smiling at the camera ',
            // sub: 'Dmitriy Frantsev'
          },
          {
            src: "https://images.unsplash.com/photo-1577277625082-36df4915ebeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
            desc: 'Blonde woman wearing sunglasses smiling at the camera ',
            sub: 'Dmitriy Frantsev'
          }
      ]
      var settings = {
        columnCount:{
          default:3,
          mobile:3,
          tab:4
        },
        mode: 'dark'
      }
  return (
    
    <>
    
    <Lightroom images={props.newdata} settings={settings} />
    </>
  )
}

export default Gallery