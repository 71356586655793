import React, { useEffect, useState } from 'react'

import {Container, Col, Row, Form, Button , Modal, Accordion } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../css/admin.css'
import Dropdown from 'react-bootstrap/Dropdown';
import { CMSMenuData } from '../menu/cmsmenudata';

import Offcanvas from 'react-bootstrap/Offcanvas';
import { homeGet, noticeGetApi, noticePutApi } from '../../api/Service.dashboard';
import MyHelmet from '../../../pages/helmet/MyHelmet';


const Sidebar = ({children}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [ham,setHam] = useState(false);
    const [addNewClass,setaddNewClass] = useState(`open-slide`);


    useEffect(()=>{
        console.log('clicked')
        if(ham===true){
            setaddNewClass('close-slide')
        }
        else{
            setaddNewClass('open-slide')
        }
    },[ham])




              /* api call */
              const [NoticeData, setNoticeData] = useState([]);
              const [NoticeActData, setNoticeActData] = useState([]);

              const apiNoticeGet =  async () =>{
                let res = await noticeGetApi();
                setNoticeData(res.data);
                setNoticeActData(res.active_total)
              }
              useEffect(()=>{
                apiNoticeGet();
              },[])

              const checkedNotice = async (id) =>{
                let res = await noticePutApi(id);
                console.log(res);
                apiNoticeGet();
              }



  return (<>
<MyHelmet meta_title="Admin Dashboard"  />
  
    <Container fluid className='cms-sidebar-wrapper'>
        <Row>
            <Col  className='sidebar-header col-12'>
                <div className="header-con-1 d-flex align-items-center">
                <div className="logo-box">
                    <img src="/images/logo1.png" alt="img"  />
                </div>
            
                <div className="side-close-btn">
                    <Button className='d-md-block d-none' onClick={()=>{setHam(!ham)}}><i class={ham ===false ? `fa-solid fa-bars` :`fa-solid fa-xmark` }></i></Button>

                    <Button variant="primary" className='d-md-none d-block' onClick={handleShow}> <i class="fa-solid fa-bars"></i> </Button>

                </div>
                </div>

                <div className="profile-wrapper my-auto ms-auto d-flex align-items-center justify-content-between">
                <div class="dropdown bell-dropdown me-2">
                      <button class="btn btn-secondary dropdown-toggle lg-btn d-flex align-items-center" data-count={NoticeActData || 0} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fa-solid fa-bell " ></i>
                      </button>
                      <ul class="dropdown-menu lg-btn-dd">
                          <div className="notif-area">

                          { NoticeData.length===0?  <span className='p-2'>No Notification</span> : 
                            NoticeData?.map((val)=>{
                              return(<>
                                <li className='menu-box  d-flex justify-content-start align-items-start  flex-column'> 
                        <strong>{val.title}</strong>
                        <span>{val.discription}</span>
                        {val.link ? <a href={val.link}>{val.link}</a> : null } 
                        <i class="fa-regular fa-trash-can" onClick={()=>{checkedNotice(val._id)}}></i>
                        </li>
                              </>)
                            })
                          }
                          {/* <li className='menu-box tx-ep-1'> 
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?
                        </li>
                        <li className='menu-box '> 
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?
                        </li>
                        <li className='menu-box '> 
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?
                        </li>

                        <li className='menu-box '> 
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?
                        </li>
                        <li className='menu-box '> 
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?
                        </li>
                        <li className='menu-box '> 
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?
                        </li>

                        <li className='menu-box '> 
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?
                        </li>
                        <li className='menu-box '> 
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?
                        </li>
                        <li className='menu-box '> 
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et veritatis omnis iste ex cumque repudiandae?
                        </li> */}
                          </div>
                       </ul>
                </div>

                <div class="dropdown profile-dropdown">
                      <button class="btn btn-secondary dropdown-toggle lg-btn d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <div className="profile-image-box">
                                        <img src="/images/user.png" alt="img" />
                                    </div>
                                    <i class="fa-solid fa-gear"></i>
                      </button>
                      <ul class="dropdown-menu lg-btn-dd">
                        <li className='menu-box'><NavLink to='/admin/profile' className='menu-link'> <i class="fa-solid fa-user"></i> My Profile</NavLink></li>
                        <li className='menu-box'><NavLink to='/admin/change-password' className='menu-link'><i class="fa-solid fa-key"></i> Change Password</NavLink></li>
                        <li className='menu-box'> <NavLink to='/' className='menu-link'><i class="fa-solid fa-right-from-bracket"></i> Logout</NavLink> </li>
                      </ul>
                </div>
              </div>


            </Col>
         
            <Col className='p-0 d-flex cms-box-container'>
            <div className={ `${addNewClass} side-menu-box-holder d-md-block d-none`} >


        


   
   <ul className="list-unstyled menu-ul">



   {
    CMSMenuData.map((val)=>{
        return(<>
            <li className='side-menu-li'>{ val.submenu ?        
            
            <Accordion className='menu-accordion'>
        <Accordion.Item eventKey="0" className='rounded-0 acc-item'>
          <Accordion.Header className='border-0 p-0 m-0 acc-header'>{val.icon} <span className="link-name">{val.title}</span></Accordion.Header>
          <Accordion.Body className='rounded-0 p-0 acc-body'>
            {
                val.submenu.map((val)=>{
                    return( <>
      <NavLink className='side-menu-link'  to={val.path}>{val.icon} <span className="link-name">{val.title}</span> </NavLink> 
                    </> )
                })
            }    
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> 
      
      :  
      
      
      <NavLink className='side-menu-link'  to={val.path}>{val.icon} <span className="link-name">{val.title}</span> </NavLink>  } </li>

        </>)
    })
   }
       </ul>
   </div>

   <Offcanvas show={show} onHide={handleClose} className='offcanvs-start'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='offcanvs-body'>
         
   <ul className="list-unstyled menu-ul">
{
 CMSMenuData.map((val)=>{
     return(<>
         <li className='side-menu-li' >{ val.submenu ?        
         
         <Accordion className='menu-accordion'>
     <Accordion.Item eventKey="0" className='rounded-0 acc-item'>
       <Accordion.Header className='border-0 p-0 m-0 acc-header'>{val.icon} <span className="link-name">{val.title}</span></Accordion.Header>
       <Accordion.Body className='rounded-0 p-0 acc-body'>
         {
             val.submenu.map((val)=>{
                 return( <>
   <NavLink className='side-menu-link' to={val.path}>{val.icon} <span className="link-name">{val.title}</span> </NavLink> 
                 </> )
             })
         }    
       </Accordion.Body>
     </Accordion.Item>
   </Accordion> 
   
   :  
   
   
   <NavLink className='side-menu-link'  to={val.path}>{val.icon} <span className="link-name">{val.title}</span> </NavLink>  } </li>

     </>)
 })
}
    </ul>
        </Offcanvas.Body>
      </Offcanvas>


   <div className="content-holder ">
            {children}    
   </div>
            </Col>
   
        </Row>
    </Container>
  
  
  </>)
}

export default Sidebar