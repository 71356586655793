

import React, { useEffect, useState } from 'react'
import {Container, Col, Row, Form, Button , Modal, Accordion } from 'react-bootstrap'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BlogCategoryDelete, BlogCategoryGet, BlogCategoryPost, BlogCategoryPut } from '../../../api/Service.dashboard';
import { SweetAlert } from '../../alert/SweetAlert';
import '../../css/main.css'
import Bootmodel from '../bootstrapmodel/Bootmodel';


const AddCategory = () => {

  const [value, setValue] = useState('');

  let submitBlog = (e)=>{
      e.preventDefault();
  }


  const [cName , setCName] = useState();
  const [cState , setCState] = useState();

              /* api call */
              const [CategoryGetData, setCategoryGetData] = useState([]);
              const apiBlogCategoryGet =  async () =>{
                let res = await BlogCategoryGet();
                setCategoryGetData(res.data)
              }
              useEffect(()=>{
                apiBlogCategoryGet();
              },[])


              const apiBlogCategoryPost =  async (e) =>{
                e.preventDefault();
                console.log(cName,cState , '=====');

                let res = await BlogCategoryPost({cName,cState});
               
                if(res.status===true){
                  SweetAlert({title:'Category Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
                }
                apiBlogCategoryGet();
              }

              const apiBlogCategoryPut =  async (bId) =>{
                console.log(cName,cState , 'update value')
                let res = await BlogCategoryPut(cName,bId,cState);
               
                if(res.status===true){
                  SweetAlert({title:'Category Update' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
                }
                apiBlogCategoryGet();
              }


              const apiBlogCategoryDelete =  async (bID) =>{
                let res = await BlogCategoryDelete(bID);
                if(res.status===true){
                  SweetAlert({title:'Category Delete' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
                }
                apiBlogCategoryGet();
              }


              const [modalShow, setModalShow] = React.useState(false);
              const [catOldData, setcatOldData] = React.useState(false);

              const [catOldDataState, setcatOldDataState] = useState();


              const activeM = (MType , val) =>{
                setModalShow(true)
                setcatOldData(val)
                setcatOldDataState(val.state)
              }

  return (<>
  
  <Container fluid className="container-fluid ">
  
        
          <Form action="" className='row'>

          <Col md={4} className="">
          
          <div className="card-body-w box-s">
          <p>Add Category</p>
          <hr />

          <CategoryForm saveType='save' statetter={catOldDataState} getState={setCState} getData={setCName} addCatFn={apiBlogCategoryPost} />
          {/* <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Name</label>
              <input type="text" name="" id="" className='form-control rounded-1' onChange={(e)=>{setCName(e.target.value)}} />
            </div>


            <div className="form-group my-3">
             <button className='btn-cms'>Save</button>
             </div> */}

          </div>
          </Col>
          <Col md={8} className="">
          <div className="card-body-w box-s">
            <h6>Category list</h6>
            <hr />

            <div className="table-responsive">
            <table className="table bg-white table-hover box-s-n">
              <thead>
                <tr>
                  <th> <div> Name </div></th>
                  <th> <div> Date </div></th>
                  <th> <div> Status </div></th>
                  <th> <div> Action </div></th>
                </tr>
              </thead>

              <tbody>
               {
                  CategoryGetData?.map((val)=>{
                    return(<>
                      <tr>
                  <td> <div className="cursor-pointer  hov-b-t "> {val.title} </div></td>
                  <td> <div className="cursor-pointer"> {val.updatedAt.slice(0,10)} </div></td>
                  <td> <div className="cursor-pointer"> <button className="btn-tag">{val.state===true ? 'publish' : 'draft'}</button> </div></td>
                  <td> <div className='d-flex'><i className="fa-solid fa-pen cursor-pointer i-c hov-g me-1" onClick={() => activeM('Home' , val)}></i> <i class="fa-regular fa-trash-can cursor-pointer  i-c hov-r" onClick={()=>{apiBlogCategoryDelete(val._id)}}></i></div> </td>
                </tr>
              
                    </>)
                  })
                } 
               

                <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)} 
                addFn={apiBlogCategoryPost}
                variable={catOldData}
                getData={setCName}
                getState={setCState}
                updateCat={apiBlogCategoryPut}
                />
                
              </tbody>
            </table>
            </div>

          </div>
          </Col>


          </Form>

    </Container>
  
  
  </>)
}

export default AddCategory;



function MyVerticallyCenteredModal(props) {

  console.log(props)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.type} Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form className='row'>
    <CategoryForm  saveType ='Update' newCat={props.addFn} id={props.variable.id} title={props.variable.title} state={props.variable.state} getData={props.getData} getState={props.getState} updateFn={()=>{props.updateCat(props.variable._id)}} />
    </Form>

      </Modal.Body>

       

      <Modal.Footer>
        <button onClick={props.onHide} className='btn-cms'>Close</button>
      </Modal.Footer>
    </Modal>
  );
}


export const CategoryForm = (propsData) =>{

  const [Check , setCheck] = useState(propsData.state);



  return(<>

<div className="form-group my-3">
              <label htmlFor="" className="label-tag">Name</label>
              <input type="text" name="" id="" className='form-control rounded-1' onChange={(e)=>{propsData.getData(e.target.value)}} defaultValue={propsData.title} />
            </div>


            {

              propsData.saveType ==='Update' ? 
          
              <div className="form-group my-3">
            <Form.Check 
        type="switch"
        id="custom-switch"
        label="Category state"
        checked={Check}
        onChange={()=>{propsData.getState(!Check);setCheck(!Check)}}
      />


</div>
 :

 <div className="form-group my-3">
              <label htmlFor="" className="label-tag">State</label>
              <select className='form-select' name="" id=""  onChange={(e)=>{propsData.getState(e.target.value)}}>
                <option value="true">publish</option>
                <option value="false">draft</option>
              </select>
            </div>
            }


            {
              propsData.show===true ? 
              <div className="form-group my-3">
              <label htmlFor="" className="label-tag">State</label>
              <select className='form-select' name="" id=""  onChange={(e)=>{propsData.getState(e.target.value)}}>
               {propsData.variable.state===true ? <option value="true" selected>publish</option> : <option value="true">publish</option>} 
               {propsData.variable.state===false ? <option value="false" selected>draft</option> : <option value="false">draft</option>} 
                {/* <option value="false">draft</option> */}
              </select>
            </div>
             : null
            }

             <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" className='btn-cms' onClick={(e)=>{ propsData.saveType ==='Update' ? propsData.updateFn(e)  :   propsData.addCatFn(e)}} >
         {propsData.saveType}
        </Button>
        </Form.Group> 


  </>)
}


