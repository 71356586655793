import React, { useEffect, useState } from 'react'
import { Col, Row,Table, Form, Button , Modal, Accordion } from 'react-bootstrap'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ContactGet, ContactMailApi } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';

const AdminContact = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalTypo, setModalTypo] = React.useState();
  const [CEmail, setCEmail] = React.useState();
  const activeM = (id,email) =>{
    setModalShow(true)
    setModalTypo(id)
    setCEmail(email)
  }

          /* api call */
          const [ContactData, setContactData] = useState([]);
          const apiContact =  async () =>{
            let res = await ContactGet();
            setContactData(res.data)
          }
          useEffect(()=>{
            apiContact();
          },[])


          // const [MailData, setMailData] = useState({
          //   email : "",
          //   subject : "",
          //   message : ""
          // });

          // const handelMailInp = (e) =>{
          //   let type = e.target.type ;
          //   let name = e.target.name ;
          //   let value;
          //   if(type==='file'){
          //     value = e.target.files[0] ;
          //   }
          //   else{
          //     value = e.target.value;
          //   }
          //   setMailData({...MailData , [name] : value});
          // }
          const apiMailContact =  async (email,subject,message) =>{
           
            let res = await ContactMailApi({email,subject,message});
            console.log(res)

            if(res.status===true){
              SweetAlert({title:'Mail' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
            }

          }

  return (<>

<MyVerticallyCenteredModal
        show={modalShow}
        type={modalTypo}
        onHide={() => setModalShow(false)}
        email={CEmail}
        callMailFn={apiMailContact}
      />

  
<Row>
    <Col>
        <div className="card-body-w box-s table-responsive">
        <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th style={{'max-width' : "150px"}} >#</th>
          <th style={{'max-width' : "150px"}} >Name</th>
          <th style={{'max-width' : "150px"}} >Email</th>
          <th style={{'max-width' : "150px"}} >Subject</th>
          <th style={{'max-width' : "250px"}} >Message</th>
          <th  style={{'width' : "100px"}}>Action</th>
        </tr>
      </thead>
      <tbody>
      {
        ContactData?.map((val)=>{
          return(<>
            <tr  key={val._id}>
          <td><span className='text-truncate'>{val._id}</span></td>
          <td>{val.name}</td>
          <td>{val.email}</td>
          <td>{val.subject}</td>
          <td>{val.discription}</td>
        <td><i class="fa-solid fa-paper-plane text-info cursor-pointer" onClick={() => activeM(val._id,val.email)}  title='send email'></i></td>
        </tr>
          </>)
        })


      }


      </tbody>
    </Table>
        </div>
    </Col>
</Row>
  
  
  </>)
}

export default AdminContact


function MyVerticallyCenteredModal(props) {

  console.log(props)


  let [email , setEmail] = useState(props.email);
  let [Subject , setSubject] = useState();
  let [Message , setMessage] = useState();

  let runFn = (e) =>{
    e.preventDefault();
    setEmail(props.email)
    props.callMailFn(email,Subject,Message);
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.type} Send Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={runFn}>
          <Form.Group className='mb-3'>
            <Form.Label>To</Form.Label>
            <Form.Control type='email' defaultValue={props.email}  disabled/>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Subject</Form.Label>
            <Form.Control type='text' placeholder='subject' onChange={(e)=>{setSubject(e.target.value)}} />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Message</Form.Label>

            <ReactQuill theme="snow" value={Message} onChange={setMessage} className="rounded-1"   />

            {/* <Form.Control as='textarea' rows={5} placeholder='message' onChange={(e)=>{setMessage(e.target.value)}} /> */}
          </Form.Group>

          <Form.Group className='mb-3'>
          <Button type='submit'><i class="fa-solid fa-paper-plane"></i> Send</Button>
          </Form.Group>

        </Form>

      </Modal.Body>

       

      <Modal.Footer>
        <button onClick={props.onHide} className='btn-cms'>Close</button>
      </Modal.Footer>
    </Modal>
  );
}