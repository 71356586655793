import React, { useEffect, useState,useRef } from 'react'
import '../css/main.css'
import ImageUploading from "react-images-uploading";
import { Container,Row,Col,Button,Form } from 'react-bootstrap';
import { GalleryDelete, GalleryGet, GalleryPost } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';

const AdminGallery = () => {
  const formRef = useRef();

  const [value, setValue] = useState('');
  const [selected, setSelected] = useState(["school"]);
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const [gImg, setgImg] = useState();

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    setgImg(imageList);
  };

  const [gName, setgName] = useState('');
  const [gAlt, setgAlt] = useState('');




  // let submitBlog = (e)=>{
  //     e.preventDefault();
  // }


            /* api call */
            const [GalleryData, setGalleryData] = useState([]);
            const apiGallery =  async () =>{
              let res = await GalleryGet();
              setGalleryData(res.data)
            }
            useEffect(()=>{
              apiGallery();
            },[])

            const apiGalleryPost =  async (e) =>{
              e.preventDefault();
              let res = await GalleryPost(gName,gAlt,images[0].file);
              apiGallery();
              SweetAlert({title:'Upload' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
              formRef.current.reset();
              setImages(null);
            }

            const apiGalleryDelete =  async (gID) =>{
              let res = await GalleryDelete(gID);
              
              SweetAlert({title:'Deleted' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
              apiGallery();
            }
          

  return (<>
  
  <Container fluid className="">
    
        
          <Form action="" className='row' onSubmit={apiGalleryPost} ref={formRef}>

          <Col md={4} className="">
          
          <div className="card-body-w box-s">
          <p>Add Image</p>
          <hr />

          <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Name</label>
              <input type="text" name="" id="" className='form-control rounded-1' onChange={(e)=>{setgName(e.target.value)}}  />
            </div>


            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Alt img</label>
              <input type="text" name="" id="" className='form-control rounded-1' onChange={(e)=>{setgAlt(e.target.value)}}  />
            </div>

            <div className="form-group my-3">
            {/* <input type="file" name="" id="" onChange={(e)=>{setgImg(e.target.files[0])}} /> */}
          <ImageUploading
        
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button className='btn-cms'
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Upload Image
            </button>
            &nbsp;


            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button className='btn-del' onClick={() => onImageRemove(index)}><i class="fa-regular fa-trash-can"></i></button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
      </div>
      <div className="form-group my-3">
        <input type="submit" value="Save" className='btn-cms' />
      </div>

          </div>
          </Col>
          <Col Col md={8} className="" >
          <div className="card-body-w box-s">
            <h6>Galler list</h6>
            <hr />

           <div className="gallery-box row">
           {
            GalleryData.map((val)=>{
              return(<>
                <Col md={4} className="">
              <img src={val.src} alt="" height='150px' width='100%' />
              <button className='btn-del' onClick={()=>{apiGalleryDelete(val._id)}}><i class="fa-regular fa-trash-can"></i></button>
            </Col>
              </>)
            })
           }
          
           </div>

          </div>
          </Col>


          </Form>
        
  
    </Container>
  
  
  </>)
}

export default AdminGallery