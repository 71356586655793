import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import Index from '../breadcrumb/Index'
import ProgressBar from 'react-bootstrap/ProgressBar';

import Modal from 'react-bootstrap/Modal';
import { servicepage } from '../apiservice/Service.api';
import MyHelmet from '../helmet/MyHelmet';
import Skeleton from 'react-loading-skeleton';
import Nodata from '../nodata/Nodata';
const Service = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [servicepageData, setservicepageData] = useState([])

  const servicepageApi =  async () =>{
    let res = await servicepage();
    setservicepageData(res.data[0]);

    console.log(res.data[0] , 'service page')
  }
  useEffect(()=>{
    servicepageApi();
  },[])

let count = 1;


  return (<>
        <MyHelmet meta_title={servicepageData.meta_title} meta_description={servicepageData.meta_description} meta_keyword={servicepageData.meta_keyword} />

  <Index page='Service'/>
    <div className="about-wrapper">
    <div className="about-title-box">
      <h2 className='mb-5'>What I Do</h2>
    </div>

<Row>

{ 

  servicepageData.service_type?.map((val)=>{
    return(<>

<Col sm={6} md={4}  className='my-4 col-half' key={val._id} > 

    <Modal size="lg" centered className='model-all' show={show} onHide={handleClose}>
        <div className="model-box">
        <Modal.Header closeButton className="model-header">
              <Modal.Title className='model-title'>{val.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body  className='model-body'>
            
            <p> {val.discription}</p>
            
            
            </Modal.Body>
      
        </div>
          </Modal>

      <div className="card-body service-card">
        <span className="sn-tag">{count++}</span>
        <h4>{val.title}</h4>
        <p className='tx-ep-3'>{val.discription}</p>
      <div className="btn-box">
      <button onClick={()=>{handleShow()}}>Read More</button>
      </div>
      </div>
</Col>
    </>)
  })

 ||  
 
<Spinner variant="light" animation="grow" className='mx-auto' />


}



{/* <Col sm={6} md={4}  className='my-4 col-half' > 
    <div className="card-body service-card">
      <span className="sn-tag">02</span>
      <h4>Web Design</h4>
      <p className='tx-ep-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque dolore atque in corrupti, sunt odit.</p>
      <div className="btn-box">
     <button onClick={()=>{handleShow()}}>Read More</button>
     </div>
    </div>
</Col> */}

{/* <Col sm={6} md={4}  className='my-4 col-half' > 
    <div className="card-body service-card">
      <span className="sn-tag">03</span>
      <h4>Web Design</h4>
      <p className='tx-ep-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque dolore atque in corrupti, sunt odit.</p>
      <div className="btn-box">
     <button onClick={()=>{handleShow()}}>Read More</button>
     </div>
    </div>
</Col>

<Col sm={6} md={4}  className='my-4 col-half' > 
    <div className="card-body service-card">
     
      <span className="sn-tag">04</span>
      <h4>Web Design</h4>
      <p className='tx-ep-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque dolore atque in corrupti, sunt odit.</p>
      <div className="btn-box">
     <button onClick={()=>{handleShow()}}>Read More</button>
     </div>
  
    </div>
</Col>

<Col sm={6} md={4}  className='my-4 col-half' > 
    <div className="card-body service-card">
      <span className="sn-tag">05</span>
      <h4>Web Design</h4>
      <p className='tx-ep-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque dolore atque in corrupti, sunt odit.</p>
      <div className="btn-box">
     <button onClick={()=>{handleShow()}}>Read More</button>
     </div>
    </div>
</Col>

<Col sm={6} md={4}  className='my-4 col-half' > 
    <div className="card-body service-card">
      <span className="sn-tag">06</span>
      <h4>Web Design</h4>
      <p className='tx-ep-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque dolore atque in corrupti, sunt odit.</p>
      <div className="btn-box">
     <button onClick={()=>{handleShow()}}>Read More</button>
     </div>
    </div>
</Col> */}


</Row>






    </div>
  
  
  </>)
}

export default Service