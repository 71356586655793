import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { MenuData } from './menudata';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Menu = ({children}) => {

    console.log(MenuData , '/MenuData')
  return (<>
  
  <Container fluid className='' id='web-container'>
      <Row>
        <Col lg={3} className='menu-holder-col d-lg-block d-none'>
        
        <div className="card-body">
            <div className="site-title">Hello!</div>
        <ul className="list-unstyled">
           {MenuData.map((val)=>{
            return(<>
                <li key={val.id}> <NavLink  to={`${val.path}`} className='menu-link text-decoration-none'>{val.title}</NavLink> </li>  
            </>)
           })}
        </ul>

        <div className="search-box">
            <input type="text" className="seact-inp" placeholder='search ...' />
            <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <div className="copy-right-box">
            <ul className="list-unstyled">
                <li title='Prortfolio Buddy - IT Software'>© 2022 Prortfolio Buddy</li>
                <li><a href="http://rahulsharma.com.np/" className='text-decoration-none text-white'>Created by Rahul Sharma</a></li>
            </ul>
        </div>
        </div>
        </Col>

        <Col xs={12} className='menu-holder-col d-lg-none d-block'>
        <Navbar bg="" expand="lg" className='navbar-header sticky-none'>
      <Container fluid>
        <Navbar.Brand className='nav-logo' href="#">Hello!</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" className='btn-tg' />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 nav-link-container"
            // style={{ maxHeight: '100px' }}
            navbarScroll
          >

{MenuData.map((val)=>{
            return(<>
                <span className='mb-2'> <NavLink  to={`${val.path}`} className='menu-link text-decoration-none'>{val.title}</NavLink> </span>  
            </>)
           })}

            {/* <Nav.Link href="#action1">Home</Nav.Link>
            <Nav.Link href="#action2">Link</Nav.Link>
            <NavDropdown title="Link" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#" disabled>
              Link
            </Nav.Link> */}
          </Nav>
      
        </Navbar.Collapse>
      </Container>
    </Navbar>
        </Col>
        <Col className='component-holder-col'>
        {children}</Col>
      </Row>
    </Container>
  
  </>
  )
}

export default Menu