import React, { useEffect, useState } from 'react'

import { Col, Row, Form, Button , Modal, Accordion } from 'react-bootstrap'
import { ClientDelete, ClientPost, ClientPut, PortfolioGet, PortfolioPut } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';
import SeoForm from './SeoForm'

function MyVerticallyCenteredModal(props) {

  console.log(props)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.type} Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form className='row' onSubmit={props.updatePageFn}>
    {props.type === 'Portfolio Content' ?  
    
    <>
    <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Title</Form.Label>
        <Form.Control type="text"  className='rounded-1' defaultValue={props.PortfolioData.title} name='title' onChange={props.reqPage} />
      </Form.Group>

      <Form.Group className="mb-3 col-md-6" >
      <Button variant="primary" type="submit">
       Save
      </Button>
      </Form.Group> 
    </>
    :
      props.type === 'Client Type' ?  
      <>
<ProjectsType saveType='Add Item' reqData={props.addClient} newcollectData={props.reqClientData} />
<span>{props.type} List(s)</span>
<Accordion>
{props.PortfolioData.client_total?.map((val)=>{
  return(<>
    <Accordion.Item eventKey={val._id} className='rounded-0 bg-gray'>
  <Accordion.Header>{val.title}</Accordion.Header>
  <Accordion.Body className='rounded-0'>
  <ProjectsType key={val.title} saveType='Update' discription={val.discription} image={val.image} title={val.title} link={val.link} newcollectData={props.reqClientData} updateFn={()=>{props.UpdateClient(val._id)}} deleteFn={()=>{props.DeleteClient(val._id)}}  />
  </Accordion.Body>
</Accordion.Item>
  </>)
}) }
</Accordion>
</>
 : null 


      
      }

    


      
    </Form>

      </Modal.Body>

       

      <Modal.Footer>
        <button onClick={props.onHide} className='btn-cms'>Close</button>
      </Modal.Footer>
    </Modal>
  );
}

const AdminPortfolio = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalTypo, setModalTypo] = React.useState();

          /* api call */
          const [PortfolioData, setPortfolioData] = useState([]);
          const apiPortfolio =  async () =>{
            let res = await PortfolioGet();
            setPortfolioData(res.data[0])
          }
          useEffect(()=>{
            apiPortfolio();
          },[])


          const [collectData , setCollectData] = useState({
            title: "",
            meta_discription : 	"",
            meta_keyword : 	"",
            meta_title : 	"",
            slug : 	"",
          })
      
          const handelPortfolioInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectData({...collectData , [name] : value});
          }

          const apiPortfolioPut =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            let res = await PortfolioPut(collectData);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
    
           }



           const [collectClientData , setCollectClientData] = useState({
            title: 	"",
            discription : 	"",
            logo : 	"",
            link : 	"",
          })
      
          const handelClientInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectClientData({...collectClientData , [name] : value});
          }


           const apiClientPost =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            let res = await ClientPost(collectClientData);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiPortfolio();

    
           }
           const apiClientPut =  async (idkey) =>{
            console.log('clicked')
     
            let res = await ClientPut(collectClientData,idkey);
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiPortfolio();

    
           }
           const apiClientDelete =  async (idkey) =>{
            let res = await ClientDelete(idkey);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Deleted' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }

              apiPortfolio();
    
           }



  const activeM = (MType) =>{
    setModalShow(true)
    setModalTypo(MType)
  }

return (
  <>
  

  

    <Row>
        <Form action="" className='row' onSubmit={apiPortfolioPut}>

        <Col md={8} >
        <div className="card-body-w box-s">
          <h6>Page Content</h6>
          <hr/>

          <MyVerticallyCenteredModal
      show={modalShow}
      type={modalTypo}
      PortfolioData={PortfolioData}
      reqPage={handelPortfolioInp}
      updatePageFn={apiPortfolioPut}

      addClient = {apiClientPost}
      reqClientData = {handelClientInp}
      UpdateClient = {apiClientPut}
      DeleteClient = {apiClientDelete}


      onHide={() => setModalShow(false)}
    />
          <Form.Group className="my-2">
            <div className="list-pop">
              <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Portfolio Content</span> <span className='icon-tag' onClick={() => activeM('Portfolio Content')} ><i class="fa-solid fa-wrench"></i></span></p>
            </div>
          </Form.Group>


          <Form.Group className="my-2">
            <div className="list-pop">
              <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Client Type</span> <span className='icon-tag' onClick={() => activeM('Client Type')} ><i class="fa-solid fa-wrench"></i></span></p>
            </div>
          </Form.Group>

          <Form.Group className="form-group my-4">
            <Button className="btn-cms" onClick={(e)=>{apiPortfolioPut(e)}}>Save</Button>
          </Form.Group>


        </div>
        </Col>

        <Col md={4} className="col-md-4 col-12">
        <div className="card-body-w box-s">
          <p>SEO Information</p>
          <hr/>
          <SeoForm seoFn={handelPortfolioInp} slug={PortfolioData.slug} metaTitle={PortfolioData.meta_title} metaDisc={PortfolioData.meta_discription} metaKey={PortfolioData.meta_keyword} />
        </div>
        </Col>
        </Form>
      </Row>

  </>
)
}

export default AdminPortfolio;



export const ProjectsType = (propsData) =>{
  return(<>
  <Form className='row'>
     <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={propsData.title} name='title' onChange={propsData.newcollectData}  />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Image</Form.Label>
          <Form.Control type="file"  className='rounded-1' name='logo' onChange={propsData.newcollectData}  />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Discription</Form.Label>
          <Form.Control type="text"  className='rounded-1'  defaultValue={propsData.discription} name='discription' onChange={propsData.newcollectData}  />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Link</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={propsData.link} name='link' onChange={propsData.newcollectData}  />
        </Form.Group>

        {propsData.saveType ==='Update' ?  <Form.Group className="mb-3 d-flex flex-column " controlId="formBasicEmail">
          <Form.Label>Image</Form.Label>
        <img src={propsData.image} alt width='200px' height='200px'  />
        </Form.Group> : null }

        <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" onClick={(e)=>{ propsData.saveType ==='Update' ? propsData.updateFn(e)  :   propsData.reqData(e)}}  >
         {propsData.saveType}
        </Button>

        {propsData.saveType ==='Update' ? <Button variant="danger" className='ms-2' onClick={()=>{propsData.deleteFn()}}>
         Delete
        </Button> : null }
        
        </Form.Group>

        </Form>
  </>)
}

