import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button , Modal, Accordion } from 'react-bootstrap';
import { homeGet, homePut, socialLinkDelete, socialLinkPost, socialLinkPut } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';
import SeoForm from './SeoForm'


function MyVerticallyCenteredModal(props) {

    console.log(props)
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.type} Edit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={(e)=>{e.preventDefault()}} className='row'>
      {props.type === 'Home' ?  
      
      <>
      <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Display Name</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={props.homedata.name} name="name"  onChange={props.profilevarfn} />
          
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Discritption</Form.Label>
          <Form.Control as="textarea"  className='rounded-1' defaultValue={props.homedata.discription} name="discription"  onChange={props.profilevarfn} />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Image</Form.Label>
          <Form.Control type="file"  className='rounded-1' name="profile_image" onChange={props.profilevarfn} />
        </Form.Group>
  
        <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" type="submit" onClick={()=>{props.callbackhomeupdate()}}>
         Save
        </Button>
        </Form.Group> 
      </>
        
        : 

        props.type === 'Social' ? 

        <>
      <SocailLinks saveType='Add Item' newSocialData={props.socialvarfn} socialPostFn={props.callbacksocialupdate} />


        <span>Social List(s)</span>
      <Accordion>
        {props.homedata.social_total?.map((val)=>{
          return(<>
            <Accordion.Item eventKey={val._id} className='rounded-0 bg-gray'>
          <Accordion.Header><i class={val.icon}></i> </Accordion.Header>
          <Accordion.Body className='rounded-0'>
          
              <SocailLinks key={val._id} saveType='Update' icon={val.icon} newSocialData={props.socialvarfn} deleteFn={()=>{props.deletesocial(val._id)}} socialPutFn={()=>{props.callbacksocialputupdate(val._id)}}  link={val.link} />
         
          </Accordion.Body>
        </Accordion.Item>
          </>)
        })}
      </Accordion>


      </>

        : null 

        
        }
  
      
  
  
        
      </Form>
  
        </Modal.Body>
  
         
  
        <Modal.Footer>
          <button onClick={props.onHide} className='btn-cms'>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }

const AdminHome = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalTypo, setModalTypo] = React.useState();
    const activeM = (MType) =>{
      setModalShow(true)
      setModalTypo(MType)
    }

          /* api call */
          const [homeData, sethomeData] = useState([]);
          const apiHome =  async () =>{
            let res = await homeGet();
            sethomeData(res.data[0])
          }
          useEffect(()=>{ apiHome(); },[])


          const [CollectHome, setCollectHome] = useState({
            title : "" ,
            name :  "" ,
            discription :  "" ,
            profile_image :  "" ,
            social  :  "" ,
            slug :  "" ,
            meta_title :  "" ,
            meta_keyword :  "" ,
            meta_discription :  "" 
           });

           const handelHomeInp = (e) =>{
            let name = e.target.name;
            let type = e.target.type;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
              console.log(value)
              
            }
            else{
              value = e.target.value;
            }
            console.log(value)
            setCollectHome({...CollectHome , [name]: value})
           }

          const apiHomePut =  async (props) =>{
            let res = await homePut(CollectHome);
            if(res.status === true){
            SweetAlert({title:'Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
            }
            apiHome();
          }
          



          const [CollectSocial, setCollectSocial] = useState({
            link : "" ,
            icon :  "" 
           });
           const handelSocialInp = (e) =>{
            console.log('called' , e)
            let name = e.target.name;
            let type = e.target.type;
            let value = e.target.value;
            setCollectSocial({...CollectSocial , [name]: value})
           }
            
           const apiSocialPost =  async () =>{
            console.log(CollectSocial, 'CollectSocialCollectSocialCollectSocialCollectSocial')

            let res = await socialLinkPost(CollectSocial);
            SweetAlert({title:'Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
            console.log(res);
            apiHome();
          }
          const apiSocialPut =  async (propID) =>{
            console.log(CollectSocial, 'CollectSocialCollectSocialCollectSocialCollectSocial')
            let res = await socialLinkPut(CollectSocial,propID);
            SweetAlert({title:'Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
            console.log(res);
            apiHome();
          }

          const apiSocialDelete =  async (propID) =>{
            console.log('clicked')
            let res = await socialLinkDelete(propID);
            SweetAlert({title:'Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
            console.log(res);
            apiHome();
            // setModalShow(false);
          }

   


  return (
    <>
    
 
    

      <Row>
          <Form  className='row'>

          <Col md={8} >
          <div className="card-body-w box-s">
            <h6>Page Content</h6>
            <hr/>

            <MyVerticallyCenteredModal
        show={modalShow}
        type={modalTypo}
        homedata={homeData}
        callbackhomeupdate = {apiHomePut}
        callbacksocialupdate = {apiSocialPost}
        callbacksocialputupdate = {apiSocialPut}
        inputvar = {CollectHome}
        profilevarfn = {handelHomeInp}
        socialvarfn = {handelSocialInp}
        onHide={() => setModalShow(false)}
        deletesocial={apiSocialDelete}
      />
            <Form.Group className="my-2">
              <div className="list-pop">
                <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Profile</span> <span className='icon-tag' onClick={() => activeM('Home')} ><i class="fa-solid fa-wrench"></i></span></p>
              </div>
            </Form.Group>


            <Form.Group className="my-2">
              <div className="list-pop">
                <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Social Links</span> <span className='icon-tag' onClick={() => activeM('Social')} ><i class="fa-solid fa-wrench"></i></span></p>
              </div>
            </Form.Group>


            <Form.Group className="form-group my-4">
              <Button className="btn-cms" onClick={()=>{apiHomePut()}}>Save</Button>
            </Form.Group>


          </div>
          </Col>

          <Col md={4} className="col-md-4 col-12">
          <div className="card-body-w box-s">
            <p>SEO Information</p>
            <hr/>


            <SeoForm updateSeo={CollectHome} seoFn = {handelHomeInp} slug={homeData.slug} metaTitle={homeData.meta_title} metaDisc={homeData.meta_discription} metaKey={homeData.meta_keyword} />
          </div>
          </Col>
          </Form>
        </Row>
 


    
    </>
  )
}

export default AdminHome


export const SocailLinks = (propsData) =>{

  return(<>
  <Row className='row' >
     <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Social FontAwsome Icon</Form.Label>
          <Form.Control type="text"  className='rounded-1' placeholder='fontawsome icon class name e.g: fa-brands fa-facebook-f' defaultValue={propsData.icon}   name='icon' onChange={propsData.newSocialData}  />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Social Link</Form.Label>
          <Form.Control type="text"  className='rounded-1' placeholder='https://www....' defaultValue={propsData.link} name='link' onChange={propsData.newSocialData}  />
        </Form.Group>
  
        <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" type="submit" onClick={()=>{propsData.saveType === 'Update'? propsData.socialPutFn() : propsData.socialPostFn()}}>
         {propsData.saveType}
        </Button>

        {propsData.saveType ==='Update' ? <Button variant="danger" className='ms-2' type="submit" onClick={()=>{propsData.deleteFn()}}>
         Delete
        </Button> : null }
        </Form.Group> 

        </Row>
  </>)
}


export const outercluster = ()=>{

      console.log("count")

}