import axios from "axios"


/* headers & gloab declaration */

  export let config = {
    headers : {
      'auth-token' : localStorage.getItem('cmsauth'),
      'base-url' : process.env.REACT_APP_BASEURL,
    }
    }

  const currentURL = window.location.href 
  const currentURL2 = window.location.pathname 
  let baseurl = currentURL.split(currentURL2)
  
  export let config2 = {

    headers : {
      'user-id' : process.env.REACT_APP_ACTKEY,
      'base-url' : baseurl[0]
        
    }
  }

/* stats mail api */
export const statsGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/stats` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* home api */
export const homeGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/home` , config2)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const homePut = async (CollectHome) =>{
    try{
        let formData = new FormData();
        formData.append("title" , CollectHome.title )
        formData.append("name" ,  CollectHome.name )
        formData.append("discription" ,  CollectHome.discription )
        formData.append("profile_image" ,  CollectHome.profile_image )
        formData.append("social"  ,  CollectHome.social )
        formData.append("slug" ,  CollectHome.slug )
        formData.append("meta_title" ,  CollectHome.meta_title )
        formData.append("meta_keyword" ,  CollectHome.meta_keyword )
        formData.append("meta_discription" ,  CollectHome.meta_discription )
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/home` ,formData , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* socail api */
export const socialLinkPost = async (CollectSocial) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/social-links` ,CollectSocial , config)
        return response.data
        
    }
    catch(err){
        console.log(err)
    }
}

export const socialLinkPut = async (CollectSocial,propId) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/social-links/${propId}` ,CollectSocial , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const socialLinkDelete = async (propId) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/social-links/${propId}`, config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* about api */
export const AboutGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/about-page`, config2)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const AboutPut = async (collectData) =>{
    try{
        let formData = new FormData;
        formData.append("degination" , collectData.degination )
        formData.append("discription" , collectData.discription )
        formData.append("meta_discription" , collectData.meta_discription )
        formData.append("meta_keyword" , collectData.meta_keyword )
        formData.append("meta_title" , collectData.meta_title )
        formData.append("name" , collectData.name )
        // formData.append("cv" , collectData.cv )
        formData.append("pp_image" , collectData.profile_image )
        formData.append("slug" , collectData.slug )
        formData.append("title" , collectData.title  ) 
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/about-page` ,formData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* skills  api */
export const AboutSkillPost = async (collectData) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/about-skills` ,collectData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const AboutSkillPut = async (collectData,idkey) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/about-skills/${idkey}` ,collectData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const AboutSkillDelete = async (idkey) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/about-skills/${idkey}` , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* edu exp  api */
export const AboutEduExpPost = async (collectData) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/about-eduexp` ,collectData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const AboutEduExpPut = async (collectData,idkey) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/about-eduexp/${idkey}` ,collectData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const AboutEduExpDelete = async (idkey) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/about-eduexp/${idkey}` , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* testimonial  api */
export const AboutTestimonialPost = async (collectData) =>{
    try{
        let formData = new FormData();
        formData.append("paragraph", collectData.paragraph )
        formData.append("name" , collectData.name )
        formData.append("image", collectData.image )
        formData.append("degination" , collectData.degination )

        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/testimonial` ,formData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const AboutTestimonialPut = async (collectData,idkey) =>{
    try{
        let formData = new FormData();
        formData.append("paragraph", collectData.paragraph )
        formData.append("name" , collectData.name )
        formData.append("image", collectData.image )
        formData.append("degination" , collectData.degination )
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/testimonial/${idkey}` ,formData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const AboutTestimonialDelete = async (idkey) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/testimonial/${idkey}` , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}


/* service  api */
export const ServiceGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/service-page`, config2)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const ServicePut = async (collectData) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/service-page` ,collectData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}


/* service types  api */
export const ServiceTypePost = async (collectData) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/service-type` ,collectData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const ServiceTypePut = async (collectData,idkey) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/service-type/${idkey}` ,collectData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const ServiceTypeDelete = async (idkey) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/service-type/${idkey}` , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}


/* service achivements api */
export const ServiceAchivementsPost = async (collectData) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/service-achivements` ,collectData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const ServiceAchivementsPut = async (collectData,idkey) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/service-achivements/${idkey}` ,collectData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const ServiceAchivementsDelete = async (idkey) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/service-achivements/${idkey}` , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* client api */
export const ClientPost = async (collectData) =>{
    try{
        let formData = new FormData();
        formData.append("title" ,collectData.title )
        formData.append("logo" ,collectData.logo )
        formData.append("link" ,collectData.link )
        formData.append("discription" ,collectData.discription )
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/portfolio-client` ,formData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const ClientPut = async (collectData,idkey) =>{
    try{
        let formData = new FormData();
        formData.append("title" ,collectData.title )
        formData.append("logo" ,collectData.logo )
        formData.append("link" ,collectData.link )
        formData.append("discription" ,collectData.discription )
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/portfolio-client/${idkey}` ,formData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const ClientDelete = async (idkey) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/portfolio-client/${idkey}` , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* portfolio api */
export const PortfolioGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/portfolio-page`, config2)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}
export const PortfolioPut = async (collectData) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/portfolio-page` , collectData, config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* gallery api */
export const GalleryGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/portfolio-gallery`,config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const GalleryPost = async (gName,gAlt,gImg) =>{
    try{
        let formData = new FormData;
        formData.append( "sub" , gName )
        formData.append("alt" , gAlt  )
        formData.append( "gallery_image" , gImg )


        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/portfolio-gallery`, formData, config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const GalleryDelete = async (gID) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/portfolio-gallery/${gID}`, config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* blog content api */
export const BlogContentGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/all-blogs` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const BlogContentPost = async (collectData ,data,metakeys,blogTags,images) =>{
    try{
        let formData = new FormData;
        formData.append("title" , collectData.title)
        formData.append("tags" ,blogTags)
        formData.append("blog_image" , images)
        formData.append("category" , collectData.category)
        // formData.append("userID" , collectData.userID)
        formData.append("slug" , collectData.slug)
        formData.append("meta_title" , collectData.meta_title)
        formData.append("meta_keyword" ,metakeys)
        formData.append("meta_discription" , collectData.meta_discription)
        formData.append("discription" , data)

        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/blogs` , formData, config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const BlogContentPut = async (collectData ,data,metakeys,blogTags,blogState,bID) =>{
    try{
        let formData = new FormData;
        formData.append("title" , collectData.title)
        formData.append("tags" ,blogTags)
        formData.append("blog_image" , collectData.blog_image)
        formData.append("category" , collectData.category)
        formData.append("slug" , collectData.slug)
        formData.append("meta_title" , collectData.meta_title)
        formData.append("meta_keyword" ,metakeys)
        formData.append("meta_discription" , collectData.meta_discription)
        formData.append("discription" , data)
        formData.append("state" , blogState)

        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/blogs/${bID}` , formData, config)
        return response.data

     
    }
    catch(err){
        console.log(err)
    }
}

export const BlogContentDelete = async (blogID) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/blogs/${blogID}` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}
/* blog like api */
export const BlogContentLike = async (bID) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/blogs/${bID}` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* contact api */
export const ContactGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/contact`, config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* blog category api */
export const BlogCategoryGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/blogs-category` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const BlogCategoryPost = async (collectData) =>{
    try{
        console.log(collectData , 'collectDatacollectData')
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/blogs-category`, {
            title : collectData.cName,
            state : collectData.cState

        } , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const BlogCategoryPut = async (collectData,bID,state) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/blogs-category/${bID}` , {"title" : collectData , state:state} , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const BlogCategoryDelete = async (bID) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/blogs-category/${bID}` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* site settings api */
export const siteSettingsGet = async () =>{
    try{

        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/settings`, config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const siteSettingsPut = async (collectData) =>{
    try{
        let formData = new FormData();
        formData.append( "site_title" , collectData.site_title );
        formData.append( "site_discription" , collectData.site_discription );
        formData.append( "site_estd" , collectData.site_estd );
        formData.append( "site_phone" , collectData.site_phone );
        formData.append( "site_address" , collectData.site_address );
        formData.append( "site_email" , collectData.site_email );
        formData.append( "site_gmap" , collectData.site_gmap );
        formData.append( "fav_icon" , collectData.site_favicon );
        formData.append( "site_copyright_name" , collectData.site_copyright_name );
        formData.append( "site_copyright_link" , collectData.site_copyright_link );
        formData.append( "site_font_family" , collectData.site_font_family );
        formData.append( "site_font_color" , collectData.site_font_color );
        formData.append( "site_theme_color1" , collectData.site_theme_color1 );
        formData.append( "site_theme_color2" , collectData.site_theme_color2 );
        formData.append( "site_theme_color3" , collectData.site_theme_color3 );
        formData.append( "site_theme_color4" , collectData.site_theme_color3 );

        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/settings` ,  formData  , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}


/* cms community api */

export const siteCommuintyPageGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/community-page` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const siteCommuintyPagePut = async (collectData) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/community-page`,collectData , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const siteCommuintyPostGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/community-post` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}
export const siteCommuintyMyPostGet = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/community-post-user` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}


export const siteCommuintyPostPost = async (collectData) =>{
    try{
        let formData = new FormData;
        formData.append("post_title" , collectData.Title)
        formData.append("post_discription" ,collectData.Discription)
        formData.append("post_img" , collectData.Image)

        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/community-post` , formData, config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const siteCommuintyPostPut = async (collectData,bID) =>{
    try{
        let formData = new FormData;
        formData.append("post_title" , collectData.post_title,)
        formData.append("post_discription" ,collectData.post_title,)
        formData.append("post_image" , collectData.post_image,)

        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/community-post/${bID}` , formData, config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const siteCommuintyPostDelete = async (blogID) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/community-post/${blogID}` , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const siteCommuintyPostupvote = async (blogID) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/community-post-like/${blogID}`,{
            'authtoken' : localStorage.getItem('cmsauth'),
        } , config)
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

/* contact mail api */
export const ContactMailApi = async (collectData) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/contact-reply` , collectData ,config )
        return response.data;
    }
    catch(err){
        console.log(err)
    }
}

/* login credintial api */
export const LoginApi = async (collectData) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/login` , collectData )
        return response.data;
    }
    catch(err){
        console.log(err)
    }
}

export const RegisterApi = async (collectData) =>{
    try{
       
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/register` , collectData )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const ForgetPassApi = async (collectData) =>{
    try{
       
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/forget-password` , collectData , config2 )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const ResetPassApi = async (collectData, token) =>{
    try{
       
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/reset-password/${token}` , collectData )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const AccountApi = async () =>{
    try{
       
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/my-account` , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export const AccountUpdateApi = async (collectData) =>{
    try{

        let formData = new FormData();
        formData.append("name" , collectData.name  )
        formData.append("password" , collectData.password )
        formData.append("cpassword" , collectData.cpassword )
        formData.append("dob" , collectData.dob )
        formData.append("image" , collectData.image  )
        formData.append("phone"  , collectData.phone  )
        formData.append("country" , collectData.country  )
        formData.append("freelancer" , collectData.freelancer  )
        formData.append("degination"  , collectData.degination  )
       
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/admin` , formData , config )
        return response.data
    }
    catch(err){
        console.log(err)
    }
}


/* notification api */
export const noticeGetApi = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/notification`, config )
        return response.data;
    }
    catch(err){
        console.log(err)
    }
}

export const noticePostApi = async (collectData) =>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_APIURL}/api/notification` , collectData ,config )
        return response.data;
    }
    catch(err){
        console.log(err)
    }
}

export const noticePutApi = async (nid) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/notification/${nid}`, config2 )
        return response.data;
    }
    catch(err){
        console.log(err)
    }
}

export const noticeDeleteApi = async (nid) =>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_APIURL}/api/notification/${nid}` ,config )
        return response.data;
    }
    catch(err){
        console.log(err)
    }
}



/* dev toop api */
export const devtoolGetApi = async () =>{
    try{
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/devtool`, config )
        return response.data;
    }
    catch(err){
        console.log(err)
    }
}

export const devtoolPutApi = async (collectData) =>{
    try{
        const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/devtool` , collectData ,config )
        return response.data;
    }
    catch(err){
        console.log(err)
    }
}


// export const homePut = async () =>{
//     try{
//         const response = await axios.put(`${process.env.REACT_APP_APIURL}/api/home`)
//         return response.data
//     }
//     catch(err){
//         console.log(err)
//     }
// }