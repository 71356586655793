import React, { useEffect, useState } from 'react';
import Index from '../breadcrumb/Index';
import { Col, NavLink, Row } from 'react-bootstrap'
import Gallery from './Gallery';
import { portfoliopage } from '../apiservice/Service.api';
import MyHelmet from '../helmet/MyHelmet';
import Nodata from '../nodata/Nodata';


const Portfolio = () => {

  const [portfoliopageData, setportfoliopageData] = useState([])
  const [portfolioGalleryData, setportfolioGalleryData] = useState([])


  const portfoliopageApi =  async () =>{
    let res = await portfoliopage();
    setportfoliopageData(res.data[0]);
    setportfolioGalleryData(res.data[0].gallery_total)
    console.log(res.data[0] , 'portfolio page')
  }
  useEffect(()=>{
    portfoliopageApi();
  },[])

  return (<>
        <MyHelmet meta_title={portfoliopageData.meta_title || 'My Portfolio'} meta_description={portfoliopageData.meta_description} meta_keyword={portfoliopageData.meta_keyword} />

  <Index page='Portfolio' />
    <div className="about-wrapper portfolio-wrapper">
    <div className="about-title-box">
      <h2 className='mb-5'>Portfolio</h2>
    </div>
    
    <Row>

    {

      portfoliopageData.length ? 

      portfoliopageData.client_total?.map((val)=>{
        return(<>
          <Col md={4} className='mb-4' key={val._id}>
        <div className="card-body profolio-card">
          <div className="imgBox">
            <img src={val.image} alt="" />
          </div>
          <div className="paraBox">
            <h4 className='title-tag tx-ep-1'>{val.title}</h4>
            <a href={val.link} className='tx-ep-1'>{val.link}</a>
          </div>
        </div>
      </Col>
        </>)
      })

      :  <Nodata/>

    }
    </Row>

    <div className="about-title-box mt-5">
      <h2 className='mb-5'>Gallery</h2>
    </div>
    <Row>
      <Col>
      {
        portfolioGalleryData.length ? <Gallery newdata={portfolioGalleryData} /> :  <Nodata/>
        
      }
    
      </Col>
    </Row>

    
    </div>
  
  
  </>)
}

export default Portfolio