import React, { useEffect, useState } from 'react';
import { homepage, statsPut } from '../apiservice/Service.api';
import {Helmet} from "react-helmet";
import MyHelmet from '../helmet/MyHelmet';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const Index = () => {

  
              /* api call */
              const apistats =  async () =>{
                let res = await statsPut();
              }
              useEffect(()=>{
                apistats();
              },[])

              const [homepageData, sethomepageData] = useState([])
              const homepageApi =  async () =>{
                let res = await homepage();
                sethomepageData(res.data[0]);
                console.log(res.data[0] , 'home page')
              }
              useEffect(()=>{
                homepageApi();
              },[])


  return (
    <>



      <MyHelmet meta_title={homepageData.meta_title || 'My Home'} meta_description={homepageData.meta_description} meta_keyword={homepageData.meta_keyword} />
 

        <div className="card-body profile-card">
            <div className="imgBox">
            <img src={homepageData.profile_image || `/images/noimg.png`} alt="" />
            </div>
            <div className="paraBox">
               <h1>{homepageData.name || <Skeleton baseColor="#202020" highlightColor="#444" />} </h1>
               <p>{homepageData.discription || <Skeleton baseColor="#202020" highlightColor="#444" />}</p>
               <ul className="list-unstyled social-ul">
               {
                homepageData.social_total?.map((val)=>{
                  return(<>
                    <li key={val._id}> <a href={val.link}><i class={val.icon}></i></a> </li>
                  </>)
                })
               }
               </ul>
            </div>

            <ul className="animate-box">
                <li className='anime'></li>
                <li className='anime'></li>
                <li className='anime'></li>
                <li className='anime'></li>
                <li className='anime'></li>
                <li className='anime'></li>
                <li className='anime'></li>
                <li className='anime'></li>

            </ul>
        </div>

    </>
  )
}

export default Index