import React, { useEffect, useState } from 'react'
import { Col, NavLink, Row } from 'react-bootstrap'
import Index from '../breadcrumb/Index';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ScrollToTop from '../../ScrollToTop';
import { useParams } from 'react-router-dom';
import { blogcommentpage, blogpostlike, blogsinglepage } from '../apiservice/Service.api';
import { SweetAlert } from '../../cms/page/alert/SweetAlert';
import { useRef } from 'react';
import MyHelmet from '../helmet/MyHelmet';

const BlogDetail = () => {
  let params = useParams();
  let formRef = useRef();

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);

      contactpageApi(commentData)
    };
  

    const [blogpageData, setblogpageData] = useState([])
    const [totalComment, settotalComment] = useState([])
    const [postBy, setpostBy] = useState([])
    const [Date, setDate] = useState([])
    const [Time, setTime] = useState([])
    const [Tags, setTags] = useState([])


    const allblogpageApi =  async () =>{
      let res = await blogsinglepage(params.id);
      setblogpageData(res.data[0]);

      if(res.data[0].total_comment.length>0){
        settotalComment(res.data[0].total_comment[0].total_comment)
      }
      else{
        console.log('not comment')
      }
      // settotalComment(res.data[0].total_comment[0].total_comment)
      setpostBy(res.data[0].posteBy[0].name)
      setDate(res.data[0].createdAt.slice(0,10))
      setTime(res.data[0].createdAt.slice(11,16))
      setTags(res.data[0].tags.toString())

      console.log(res.data , 'allblog page')

      console.log(postBy , Date ,Time , Tags , 'postBy , Date ,Time , Tags')

    }
    useEffect(()=>{
      allblogpageApi();
    },[])


    const [commentData, setcommentData] = useState({
      name: '',
      email : '',
      comment : '',
    })
  
    const handelCommentInp = (e) =>{
      console.log('called' , e)
      let name = e.target.name;
      let value = e.target.value;
      setcommentData({...commentData , [name]: value})
     }
  
  
     const contactpageApi =  async (commentData) =>{
      console.log(commentData , 'commentDatacommentData')
       let res = await blogcommentpage(commentData,params.id);
       if(res.status===true){
        SweetAlert({title:'comment Status' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
       formRef.current.reset()
       allblogpageApi();
      }
     }

     const postlikeApi =  async () =>{
       let res = await blogpostlike(params.id);
       if(res.status===true){
       
       allblogpageApi();
      }
     }



    console.log(postBy , "==postBy==")


  return (<>
  

  <MyHelmet meta_title={blogpageData.meta_title || 'My Blog'} meta_description={blogpageData.meta_description} meta_keyword={blogpageData.meta_keyword} />

  <ScrollToTop/>
  <Index page='Blogs' page1link={'/blogs'}  page2='Blogs details' />
    <div className="about-wrapper blogs-wrapper blogs-details-wrapper">
    <div className="about-title-box">
      <h2 className='mb-5'>Latest Blogs</h2>
    </div>
    
    <Row>
      <Col md={8} className='blog-col-1' >
        <div className="card-body blog-card-wrapper mb-5">
          <div className="imgBox">
            <img src={blogpageData.image} alt="" />
          </div>
          <div className="para-box">
            <ul className="list-unstyled">
              <li className='d-flex justify-content-between flex-wrap'> <span className='publish-by'>By { postBy || 'unknown' } / {Date.length <= 0 ?  'unknown' : Date} / {Time.length <= 0 ?  'unknown' : Time}</span> <span className='heart-tag'><i className="fa-regular fa-heart"></i> <i className="fa-solid fa-heart active" onClick={()=>{postlikeApi()}}></i><small>{blogpageData.likes}</small></span> </li>
              <hr />
              <li> <h2 className='blog-title'>{blogpageData.title}</h2> </li>
              <li> <p className='blog-para' dangerouslySetInnerHTML={{ __html:blogpageData.discription }}></p> </li>
              <li> <span className='tag-main'><i className="fa-solid fa-sitemap"></i> Category: <span className="tag-data">{blogpageData.category}</span> </span> </li>
              <li> <span className='tag-main'><i className="fa-sharp fa-solid fa-tags"></i> Tags: <span className="tag-data">{Tags}</span> </span> </li>
            </ul>
          </div>


          <div className="all-comments">
          <p className='comment-title'>Comments ({totalComment || 0})</p>
          {
            blogpageData.result_comment_outer?.map((val)=>{
              return(<>
                <div className="comment-box" key={val._id}>
            <div className="imgBox d-flex justify-content-between align-items-center">
            <button><i className="fa-solid fa-user"></i> {val.name}</button>
            <div className="date">2022/02/01</div>
            </div>
            <div className="paraBox">
              <p>{val.comment}</p>
            </div>
          </div>
              </>)
            })
          }
       
          </div>
        </div>


        <div className="comment-box-wrapper">
            <div className="comment-form-box">
            <label htmlFor="" className="label-tag">Comment Box</label>

            <Form noValidate ref={formRef} validated={validated} onSubmit={handleSubmit}>
  <Row className="">
    
    <Form.Group className='my-3' controlId="validationCustom03">
      
      <Form.Control type="text" placeholder="Name" className='form-inp bg-transparent rounded-0 border-light' required name='name' onChange={handelCommentInp} />
      <Form.Control.Feedback type="invalid">
        Please provide a valid Name.
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group className='my-3' controlId="validationCustom03">
      
      <Form.Control type="email" placeholder="Email" className='form-inp bg-transparent rounded-0 border-light' required name='email' onChange={handelCommentInp} />
      <Form.Control.Feedback type="invalid">
        Please provide a valid Email.
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group className='my-3' controlId="validationCustom03">
       
      <Form.Control as="textarea" rows={5}  placeholder="Comment" className='textarea-inp bg-transparent rounded-0 border-light' required name='comment' onChange={handelCommentInp} />
      <Form.Control.Feedback type="invalid">
        Please provide a valid Comment.
      </Form.Control.Feedback>
    </Form.Group>
  
  </Row>
  <Form.Group className="mb-3">
    <Form.Check
      required
      className='check-inp'
      label="Save my name, email, and website in this browser for the next time I comment."
      feedback="You must agree before submitting."
      feedbackType="invalid"
      
    />
  </Form.Group>
  <Button type="submit" className='btn-all'>Post Comment</Button>
</Form>
            </div>
        </div>

      </Col>
      <Col md={4} className='blog-col-2' >
      <div className="search-box-wrapper mb-3">
      <label htmlFor="" className="label-tag">Search</label>
        <div className="search-box">
        <input type="text" className='' />
        <button>Search</button>
        </div>
      </div>
      <div className="recent-box-wrapper mb-3">
      <label htmlFor="" className="label-tag">Recent Activity</label>
        <ul className="list-unstyled">
          <li> <NavLink to='/' className='blog-link' >Hello World!</NavLink> </li>
          <li> <NavLink to='/' className='blog-link' >Best Ways To Make Your Mobile Website Load Faster</NavLink> </li>
          <li> <NavLink to='/' className='blog-link' >What To Ask When Choosing A Web Designer In Sydney</NavLink> </li>
          <li> <NavLink to='/' className='blog-link' >Skills You Need For Efficient Design</NavLink> </li>
          <li> <NavLink to='/' className='blog-link' >Hello World!</NavLink> </li>
        </ul>
      </div>

      </Col>
    
    </Row>
    
    </div>
  
  
  </>)
}

export default BlogDetail