import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button , Modal, Accordion } from 'react-bootstrap'
import { AboutEduExpDelete, AboutEduExpPost, AboutEduExpPut, AboutGet, AboutPut, AboutSkillDelete, AboutSkillPost, AboutSkillPut, AboutTestimonialDelete, AboutTestimonialPost, AboutTestimonialPut } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';
import SeoForm from './SeoForm'


function MyVerticallyCenteredModal(props) {

    console.log(props)
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.type} Edit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form className='row' onSubmit={props.updateProfile}>
      {props.type === 'Home' ?  
      
      <>
      <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Display Name</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={props.AboutData.name} name='name' onChange={props.reqData} />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Degination</Form.Label>
          <Form.Control  type="text"  className='rounded-1' defaultValue={props.AboutData.degination} name='degination' onChange={props.reqData} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Discription</Form.Label>
          <Form.Control  as="textarea"  className='rounded-1' defaultValue={props.AboutData.discription} name='discription' onChange={props.reqData} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Resume</Form.Label>
          <Form.Control  type="file"  className='rounded-1'  name='cv' onChange={props.reqData} />
        </Form.Group>


        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Image</Form.Label>
          <Form.Control type="file"  className='rounded-1' name='profile_image' onChange={props.reqData} />
        </Form.Group>
  
        <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" type="submit">
         Save
        </Button>
        </Form.Group> 
      </>
        
        : 

        props.type === 'Skills' ? 

        <>
      <Skills saveType='Add Item' reqData={props.addSkill} newcollectData={props.reqSkillData} />
        <span>{props.type} List(s)</span>
      <Accordion>
        {props.AboutData.skills_total?.map((val)=>{
          return(<>

            <Accordion.Item eventKey={val._id} className='rounded-0 bg-gray'>
          <Accordion.Header>{val.title}</Accordion.Header>
          <Accordion.Body className='rounded-0'>
          <Skills saveType='Update' title={val.title} percent={val.percent} newcollectData={props.reqSkillData} skType={val.skill_type} updateFn={()=>{props.UpdateSkill(val._id)}} deleteFn={()=>{props.DeleteSkill(val._id)}} />
          </Accordion.Body>
        </Accordion.Item>

          </>)
        }) }
      </Accordion>
      </>

        :       
        
          props.type === 'EduExp' ? 

<>
<EduExp saveType='Add Item' reqData={props.addEduExp} collectData={props.reqEduExpData} />
<span>{props.type} List(s)</span>
<Accordion>

{props.AboutData.eduexp_total?.map((val)=>{
          return(<>
            <Accordion.Item eventKey={val._id} className='rounded-0 bg-gray'>
  <Accordion.Header>{val.company}</Accordion.Header>
  <Accordion.Body className='rounded-0'>
  <EduExp saveType='Update' end={val.end} start={val.start} degination={val.degination} company={val.company} collectData={props.reqEduExpData} updateFn={()=>{props.UpdateEduExp(val._id)}} deleteFn={()=>{props.DeleteEduExp(val._id)}} />
  </Accordion.Body>
</Accordion.Item>

</>)
        }) }

</Accordion>
</>

:
props.type === 'Testimonial' ? 

<>
<Testimonial saveType='Add Item' reqData={props.addTestimonial} collectData={props.reqTestimonialData} />
<span>{props.type} List(s)</span>
<Accordion>

{props.AboutData.testimonial_total?.map((val)=>{
          return(<>
            <Accordion.Item eventKey={val._id} className='rounded-0 bg-gray'>
  <Accordion.Header>{val.name}</Accordion.Header>
  <Accordion.Body className='rounded-0'>
  <Testimonial saveType='Update' name={val.name} degination={val.degination} image={val.image} paragraph={val.paragraph} collectData={props.reqTestimonialData} updateFn={()=>{props.UpdateTestimonial(val._id)}} deleteFn={()=>{props.DeleteTestimonial(val._id)}} />
  </Accordion.Body>
</Accordion.Item>

</>)
        }) }

</Accordion>
</>

: null 


        
        }
  
      
  
  
        
      </Form>
  
        </Modal.Body>
  
         
  
        <Modal.Footer>
          <button onClick={props.onHide} className='btn-cms'>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }


const AdminAbout = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalTypo, setModalTypo] = React.useState();

    
    const activeM = (MType) =>{
      setModalShow(true)
      setModalTypo(MType)
    }

    const [collectData , setCollectData] = useState({
      degination : 	"",
      discription : 	"",
      meta_discription : 	"",
      meta_keyword : 	"",
      meta_title : 	"",
      slug : 	"",
      name : 	"",
      cv : 	"",
      profile_image : 	"",
      slug : 	"",
      title : 	"",
    })

    const handelAbtInp = (e) =>{
      let type = e.target.type ;
      let name = e.target.name ;
      let value;
      if(type==='file'){
        value = e.target.files[0] ;
      }
      else{
        value = e.target.value;
      }
      setCollectData({...collectData , [name] : value});
    }

           /* api call */
           const [AboutData, setAboutData] = useState([]);
           const apiAbout =  async () =>{
            let res = await AboutGet();
            setAboutData(res.data[0])
           }
           useEffect(()=>{
             apiAbout();
           },[])

           const apiAboutPut =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            let res = await AboutPut(collectData);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
    
           }


           const [collectSkillData , setCollectSkillData] = useState({
            title: 	"",
            percent : 	"",
            skill_type:	"",
          })
      
          const handelAbtSkillInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectSkillData({...collectSkillData , [name] : value});
          }


           const apiAboutSkillPost =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            let res = await AboutSkillPost(collectSkillData);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiAbout();

    
           }
           const apiAboutSkillPut =  async (idkey) =>{
            console.log('clicked')
     
            let res = await AboutSkillPut(collectSkillData,idkey);
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiAbout();

    
           }
           const apiAboutSkillDelete =  async (idkey) =>{
            let res = await AboutSkillDelete(idkey);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Deleted' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }

              apiAbout();
    
           }


           const [collectEduExpData , setCollectEduExpData] = useState({
            company: "",
            degination : "",
            start: "",
            end: "",
            type: "",

          })
      
          const handelAbtEduExpInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectEduExpData({...collectEduExpData , [name] : value});
          }
          const apiAboutEduExpPost =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            let res = await AboutEduExpPost(collectEduExpData);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiAbout();

    
           }
           const apiAboutEduExpPut =  async (idkey) =>{
            console.log('clicked')
            let res = await AboutEduExpPut(collectEduExpData,idkey);
            if(res.status === true){
              SweetAlert({title:'Updated' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
      
              apiAbout();
           }
           const apiAboutEduExpDelete =  async (idkey) =>{
            let res = await AboutEduExpDelete(idkey);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Deleted' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }

              apiAbout();
    
           }



           const [collectTestimonialData , setCollectTestimonialData] = useState({
            paragraph: "",
            name : "",
            image: "",
            degination: ""
          })
      
          const handelAbtTestimonialInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectTestimonialData({...collectTestimonialData , [name] : value});
          }
          const apiAboutTestimonialPost =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            let res = await AboutTestimonialPost(collectTestimonialData);
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiAbout();
           }

           const apiAboutTestimonialPut =  async (idkey) =>{
            console.log('clicked')
            let res = await AboutTestimonialPut(collectTestimonialData,idkey);
            if(res.status === true){
              SweetAlert({title:'Updated' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiAbout();
           }
           const apiAboutTestimonialDelete =  async (idkey) =>{
            let res = await AboutTestimonialDelete(idkey);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Deleted' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }

              apiAbout();
    
           }

  return (
    <>
    
 
    

      <Row>
          <Form action="" className='row'>

          <Col md={8} >
          <div className="card-body-w box-s">
            <h6>Page Content</h6>
            <hr/>

            <MyVerticallyCenteredModal
        show={modalShow}
        type={modalTypo}
        AboutData={AboutData}
        onHide={() => setModalShow(false)}
        updateProfile = {apiAboutPut}
        addSkill = {apiAboutSkillPost}
        reqSkillData = {handelAbtSkillInp}
        UpdateSkill = {apiAboutSkillPut}
        DeleteSkill = {apiAboutSkillDelete}

        addEduExp = {apiAboutEduExpPost}
        reqEduExpData = {handelAbtEduExpInp}
        UpdateEduExp = {apiAboutEduExpPut}
        DeleteEduExp = {apiAboutEduExpDelete}


        addTestimonial = {apiAboutTestimonialPost}
        reqTestimonialData = {handelAbtTestimonialInp}
        UpdateTestimonial = {apiAboutTestimonialPut}
        DeleteTestimonial = {apiAboutTestimonialDelete}


        reqData =  {handelAbtInp}
      />
            <Form.Group className="my-2">
              <div className="list-pop">
                <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Profile</span> <span className='icon-tag' onClick={() => activeM('Home')} ><i class="fa-solid fa-wrench"></i></span></p>
              </div>
            </Form.Group>


            <Form.Group className="my-2">
              <div className="list-pop">
                <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Skills</span> <span className='icon-tag' onClick={() => activeM('Skills')} ><i class="fa-solid fa-wrench"></i></span></p>
              </div>
            </Form.Group>

            <Form.Group className="my-2">
              <div className="list-pop">
                <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Education and Exprencess</span> <span className='icon-tag' onClick={() => activeM('EduExp')} ><i class="fa-solid fa-wrench"></i></span></p>
              </div>
            </Form.Group>


            <Form.Group className="my-2">
              <div className="list-pop">
                <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Testimonial</span> <span className='icon-tag' onClick={() => activeM('Testimonial')} ><i class="fa-solid fa-wrench"></i></span></p>
              </div>
            </Form.Group>

            <Form.Group className="form-group my-4">
              <Button className="btn-cms" onClick={(e)=>{apiAboutPut(e)}}>Save</Button>
            </Form.Group>


          </div>
          </Col>

          <Col md={4} className="col-md-4 col-12">
          <div className="card-body-w box-s">
            <p>SEO Information</p>
            <hr/>


            <SeoForm seoFn={handelAbtInp} slug={AboutData.slug} metaTitle={AboutData.meta_title} metaDisc={AboutData.meta_discription} metaKey={AboutData.meta_keyword} />

          </div>
          </Col>
          </Form>
        </Row>
 


    
    </>
  )
}

export default AdminAbout;



export const Skills = (propsData) =>{
  return(<>
  {/* <Form className='row' onSubmit={(e)=>{e.preventDefault()}}> */}
     <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Skill Title</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={propsData.title} name='title' onChange={propsData.newcollectData} />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Skill Precentage</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={propsData.title} name='percent' onChange={propsData.newcollectData} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Skill Type</Form.Label>
          <Form.Select  name='skill_type' onChange={propsData.newcollectData} >
          <option value="select">--select--</option>
          <option value="programming">Programming</option>
          <option value="otherskill">Other Skill</option>
          <option value="knowledge">Knowledge</option>
          <option value="intrest">Intrest</option>

          </Form.Select>

        </Form.Group>
  
        <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" onClick={(e)=>{ propsData.saveType ==='Update' ? propsData.updateFn(e)  :   propsData.reqData(e)}}  >
         {propsData.saveType}
        </Button>

        {propsData.saveType ==='Update' ? <Button variant="danger" className='ms-2' onClick={()=>{propsData.deleteFn()}}>
         Delete
        </Button> : null }
        
        </Form.Group> 

        {/* </Form> */}
  </>)
}

export const EduExp = (propsData) =>{
  return(<>
      {/* <Form className='row'> */}
     <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Company/Institute</Form.Label>
          <Form.Control type="text"  className='rounded-1' name='company' onChange={propsData.collectData} defaultValue={propsData.company} />
        </Form.Group>
  
        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Degination</Form.Label>
          <Form.Control type="text"  className='rounded-1' name='degination' onChange={propsData.collectData} defaultValue={propsData.degination} />
        </Form.Group>

        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Type</Form.Label>
          {/* <Form.Control type="text"  className='rounded-1' name='type' onChange={propsData.collectData} defaultValue={propsData.type} /> */}
          <Form.Select className='rounded-1' name='type' onChange={propsData.collectData}>
            <option>--select--</option>
            <option value='education'>Education</option>
            <option value='experience'>Experience</option>
          </Form.Select>
        </Form.Group>


        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Start Date</Form.Label>
          <Form.Control type="date"  className='rounded-1' name='start' onChange={propsData.collectData} defaultValue={propsData.start} />
        </Form.Group>

        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>End Date</Form.Label>
          <Form.Control type="date"  className='rounded-1' name='end' onChange={propsData.collectData} defaultValue={propsData.end} />
        </Form.Group>
  
        <Form.Group className="mb-3 col-md-12" >
        <Button variant="primary" onClick={(e)=>{ propsData.saveType ==='Update' ? propsData.updateFn()  :   propsData.reqData(e)}}  >
         {propsData.saveType}
        </Button>

        {propsData.saveType ==='Update' ? <Button variant="danger" className='ms-2' onClick={()=>{propsData.deleteFn()}}>
         Delete
        </Button> : null }
        
        </Form.Group>  

        {/* </Form> */}
  </>)
}

export const Testimonial = (propsData) =>{
  return(<>
  {/* <Form className='row'> */}
     <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={propsData.name} name='name' onChange={propsData.collectData} />
        </Form.Group>
  
        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Degination</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={propsData.degination} name='degination' onChange={propsData.collectData} />
        </Form.Group>
        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Image</Form.Label>
          <Form.Control type="file"  className='rounded-1' name='image' onChange={propsData.collectData}  />
          {propsData.saveType ==='Update' ? 
          <img className='mt-2' src={propsData.image} height='200px' width='200px' /> : null }
        </Form.Group>
        <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
          <Form.Label>Discritption</Form.Label>
          <Form.Control as='textarea'  className='rounded-1' defaultValue={propsData.paragraph} name='paragraph' onChange={propsData.collectData} />
        </Form.Group>

        <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" onClick={(e)=>{ propsData.saveType ==='Update' ? propsData.updateFn(e)  :   propsData.reqData(e)}}  >
         {propsData.saveType}
        </Button>

        {propsData.saveType ==='Update' ? <Button variant="danger" className='ms-2' onClick={()=>{propsData.deleteFn()}}>
         Delete
        </Button> : null }
        
        </Form.Group> 
        {/* </Form> */}
  </>)
}