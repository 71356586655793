import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { NavLink, useParams } from 'react-router-dom'
import MyHelmet from '../../../pages/helmet/MyHelmet';
import { ResetPassApi } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';

const ResetPassword = () => {

  const [password,setPassword] = useState();
  const [cpassword,setCPassword] = useState();
  const params = useParams()

  console.log(params.token)

  const resetPassFn = async (e) =>{
  try{  console.log('clicked')
    e.preventDefault();
      let res = await ResetPassApi({password,cpassword} , params.token );
      
      if(res.status === true){
        SweetAlert({title:'Reset Status' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
      }

     

      else{
        SweetAlert({title:'Password Reset' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true})
      }
    }

    catch(err){
      SweetAlert({title:'Password Reset' , msg : 'failed' , icon : 'warning', btn : 'close', dangerMode: true})
     }
  } 


  return (<>
<MyHelmet meta_title="Admin Reset password"  />
  
 <Container fluid className='log-container'>
  <Row className='p-3 h-100'>

    <div className="log-card d-flex justify-content-center align-items-center">
    <Col md={3} className='card-body-w box-s m-auto'>
      <Form onSubmit={resetPassFn}>
        <Form.Group className='my-3 m-auto d-flex justify-content-center'>
          <img src='/images/logo1.png' height='80px' width='250px' className='m-auto' />
        </Form.Group>

        <Form.Group className='my-3  d-flex justify-content-center align-items-center flex-column text-center'>
        <Form.Label > <h4>Hi, Reset Password </h4> 
        <p>Enter your credentials to continue</p> 
        </Form.Label>
        </Form.Group>

        <Form.Group className='form-group my-3 '>
        <Form.Label >New Password </Form.Label>
        <Form.Control  type="text"  onChange={(e)=>{setPassword(e.target.value)}}/>
        </Form.Group>

        <Form.Group className='form-group my-3 '>
        <Form.Label >Confirm Password </Form.Label>
        <Form.Control  type="text"  onChange={(e)=>{setCPassword(e.target.value)}}/>
        </Form.Group>

      <Form.Group className="my-4 d-flex justify-content-between">
        <Button className='btn-cms w-100'  type='submit' >Reset Password</Button>
      </Form.Group>
      </Form>
        <hr />
      <Form.Group className="my-4 d-flex justify-content-between">
      <Form.Label className='cursor-pointer mx-auto'> <NavLink className='link-tag' to='/register'>Don't have account?</NavLink>  </Form.Label>
      </Form.Group>


     </Col>
    </div>
 
  </Row>
 </Container>
  
  
  </>)
}

export default ResetPassword