import React, { useEffect, useState } from 'react'

import { Col, Row, Form, Button , Modal, Accordion } from 'react-bootstrap'
import { ServiceAchivementsDelete, ServiceAchivementsPost, ServiceAchivementsPut, ServiceGet, ServicePut, ServiceTypeDelete, ServiceTypePost, ServiceTypePut } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';
import SeoForm from './SeoForm'

function MyVerticallyCenteredModal(props) {

  console.log(props)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.type} Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form className='row' onSubmit={props.updatePageFn}>
    {props.type === 'Service Content' ?  
    
    <>
    <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Title</Form.Label>
        <Form.Control type="text"  className='rounded-1' defaultValue={props.ServiceData.title} name='title' onChange={props.reqPage} />
      </Form.Group>

      <Form.Group className="mb-3 col-md-6" >
      <Button variant="primary" type="submit" >
       Save
      </Button>
      </Form.Group> 
    </>
      
      : 

      props.type === 'Service Type' ? 

      <>
    <ServiceType saveType='Add Item' reqData={props.addServiceType} newcollectData={props.reqServiceTypeData}  />
      <span>{props.type} List(s)</span>
    <Accordion>
      { props.ServiceData.service_type?.map((val)=>{
        return(<>
          <Accordion.Item eventKey={val._id} className='rounded-0 bg-gray'>
        <Accordion.Header>{val.title}</Accordion.Header>
        <Accordion.Body className='rounded-0'>
        <ServiceType saveType='Update' title={val.title} discription={val.discription} newcollectData={props.reqServiceTypeData} updateFn={()=>{props.UpdateServiceType(val._id)}} deleteFn={()=>{props.DeleteServiceType(val._id)}}  />
        </Accordion.Body>
      </Accordion.Item>
        </>)
      })  }
    </Accordion>
    </>

      :       
      
        props.type === 'Client' ? 

<>
<Client saveType='Add Item' />
<span>{props.type} List(s)</span>
<Accordion>
<Accordion.Item eventKey="0" className='rounded-0 bg-gray'>
<Accordion.Header>Item #1</Accordion.Header>
<Accordion.Body className='rounded-0'>
<Client saveType='Update' />
</Accordion.Body>
</Accordion.Item>
</Accordion>
</>

:
props.type === 'Achivements' ? 

<>
<Achivements saveType='Add Item' reqData={props.addServiceAchivements} newcollectData={props.reqServiceAchivementsData}   />
<span>{props.type} List(s)</span>
<Accordion>
{ props.ServiceData.service_achivements?.map((val)=>{
        return(<>
          <Accordion.Item eventKey={val._id} className='rounded-0 bg-gray'>
        <Accordion.Header>{val.title}</Accordion.Header>
        <Accordion.Body className='rounded-0'>
        <Achivements saveType='Update' title={val.title} count={val.count} newcollectData={props.reqServiceAchivementsData} updateFn={()=>{props.UpdateServiceAchivements(val._id)}} deleteFn={()=>{props.DeleteServiceAchivements(val._id)}}   />
        </Accordion.Body>
      </Accordion.Item>
        </>)
      })  }
</Accordion>
</>

: null 


      
      }

    


      
    </Form>

      </Modal.Body>

       

      <Modal.Footer>
        <button onClick={props.onHide} className='btn-cms'>Close</button>
      </Modal.Footer>
    </Modal>
  );
}

const AdminService = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const [modalTypo, setModalTypo] = React.useState();

          /* api call */
          const [ServiceData, setServiceData] = useState([]);
          const apiService =  async () =>{
            let res = await ServiceGet();
            setServiceData(res.data[0])
          }
          useEffect(()=>{
            apiService();
          },[])


          const [collectData , setCollectData] = useState({
            title: "",
            meta_discription : 	"",
            meta_keyword : 	"",
            meta_title : 	"",
            slug : 	"",
          })
      
          const handelServiceInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectData({...collectData , [name] : value});
          }

          const apiServicePut =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            let res = await ServicePut(collectData);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
    
           }



           const [collectServiceTypeData , setCollectServiceTypeData] = useState({
            title: 	"",
            discription : 	"",
          })
      
          const handelServiceTypeInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectServiceTypeData({...collectServiceTypeData , [name] : value});
          }


           const apiServiceTypePost =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            let res = await ServiceTypePost(collectServiceTypeData);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiService();

    
           }
           const apiServiceTypePut =  async (idkey) =>{
            console.log('clicked')
     
            let res = await ServiceTypePut(collectServiceTypeData,idkey);
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiService();

    
           }
           const apiServiceTypeDelete =  async (idkey) =>{
            let res = await ServiceTypeDelete(idkey);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Deleted' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }

              apiService();
    
           }



           const [collectServiceAchivementsData , setCollectServiceAchivementsData] = useState({
            title:"",
            count :"",
          })
      
          const handelServiceAchivementsInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectServiceAchivementsData({...collectServiceAchivementsData , [name] : value});
          }


           const apiServiceAchivementsPost =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            let res = await ServiceAchivementsPost(collectServiceAchivementsData);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiService();

    
           }
           const apiServiceAchivementsPut =  async (idkey) =>{
            console.log('clicked')
     
            let res = await ServiceAchivementsPut(collectServiceAchivementsData,idkey);
            if(res.status === true){
              SweetAlert({title:'Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }
              apiService();

    
           }
           const apiServiceAchivementsDelete =  async (idkey) =>{
            let res = await ServiceAchivementsDelete(idkey);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Deleted' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }

              apiService();
    
           }


  const activeM = (MType) =>{
    setModalShow(true)
    setModalTypo(MType)
  }

return (
  <>
  

  

    <Row>
        <Form action="" className='row'>

        <Col md={8} >
        <div className="card-body-w box-s">
          <h6>Page Content</h6>
          <hr/>

          <MyVerticallyCenteredModal
      show={modalShow}
      type={modalTypo}
      ServiceData={ServiceData}
      reqPage={handelServiceInp}
      updatePageFn={apiServicePut}

      addServiceType = {apiServiceTypePost}
      reqServiceTypeData = {handelServiceTypeInp}
      UpdateServiceType = {apiServiceTypePut}
      DeleteServiceType = {apiServiceTypeDelete}

      addServiceAchivements = {apiServiceAchivementsPost}
      reqServiceAchivementsData = {handelServiceAchivementsInp}
      UpdateServiceAchivements = {apiServiceAchivementsPut}
      DeleteServiceAchivements = {apiServiceAchivementsDelete}


      onHide={() => setModalShow(false)}
    />
          <Form.Group className="my-2">
            <div className="list-pop">
              <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Service Content</span> <span className='icon-tag' onClick={() => activeM('Service Content')} ><i class="fa-solid fa-wrench"></i></span></p>
            </div>
          </Form.Group>


          <Form.Group className="my-2">
            <div className="list-pop">
              <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Service Type</span> <span className='icon-tag' onClick={() => activeM('Service Type')} ><i class="fa-solid fa-wrench"></i></span></p>
            </div>
          </Form.Group>

          <Form.Group className="my-2">
            <div className="list-pop">
              <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Client Type</span> <span className='icon-tag' onClick={() => activeM('Client')} ><i class="fa-solid fa-wrench"></i></span></p>
            </div>
          </Form.Group>


          <Form.Group className="my-2">
            <div className="list-pop">
              <p className='my-auto title-tag d-flex justify-content-between'><span className='my-auto'>Achivements Type</span> <span className='icon-tag' onClick={() => activeM('Achivements')} ><i class="fa-solid fa-wrench"></i></span></p>
            </div>
          </Form.Group>

          <Form.Group className="form-group my-4">
            <Button className="btn-cms" onClick={(e)=>{apiServicePut(e)}} >Save</Button>
          </Form.Group>


        </div>
        </Col>

        <Col md={4} className="col-md-4 col-12">
        <div className="card-body-w box-s">
          <p>SEO Information</p>
          <hr/>

          <SeoForm seoFn={handelServiceInp} slug={ServiceData.slug} metaTitle={ServiceData.meta_title} metaDisc={ServiceData.meta_discription} metaKey={ServiceData.meta_keyword} />

    

        </div>
        </Col>
        </Form>
      </Row>



  
  </>
)
}

export default AdminService;



export const ServiceType = (propsData) =>{
  return(<>
  {/* <Form className='row'> */}
     <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={propsData.title} name='title' onChange={propsData.newcollectData} />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Discritption</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={propsData.discription} name='discription' onChange={propsData.newcollectData} />
        </Form.Group>
  
        <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" onClick={(e)=>{ propsData.saveType ==='Update' ? propsData.updateFn(e)  :   propsData.reqData(e)}}  >
         {propsData.saveType}
        </Button>

        {propsData.saveType ==='Update' ? <Button variant="danger" className='ms-2' onClick={()=>{propsData.deleteFn()}}>
         Delete
        </Button> : null }
        
        </Form.Group>

        {/* </Form> */}
  </>)
}

export const Client = (propsData) =>{
  return(<>
  <Form className='row'>
     <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Company/Institute</Form.Label>
          <Form.Control type="text"  className='rounded-1'  />
        </Form.Group>
  
        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Degination</Form.Label>
          <Form.Control type="text"  className='rounded-1'  />
        </Form.Group>

        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Start Date</Form.Label>
          <Form.Control type="date"  className='rounded-1'  />
        </Form.Group>

        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>End Date</Form.Label>
          <Form.Control type="date"  className='rounded-1'  />
        </Form.Group>
  
        <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" type="submit">
         {propsData.saveType}
        </Button>
        {propsData.saveType ==='Update' ? <Button variant="danger" className='ms-2' type="submit">
         Delete
        </Button> : null }

        </Form.Group> 

        </Form>
  </>)
}

export const Achivements = (propsData) =>{
  return(<>
  <Form className='row'>
     <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text"  className='rounded-1' defaultValue={propsData.title} name='title' onChange={propsData.newcollectData} />
        </Form.Group>
  
        <Form.Group className="col-md-6 mb-3" controlId="formBasicEmail">
          <Form.Label>Count</Form.Label>
          <Form.Control type="Number"  className='rounded-1' defaultValue={propsData.count} name='count' onChange={propsData.newcollectData} />
        </Form.Group>

        <Form.Group className="mb-3 col-md-6" >
        <Button variant="primary" onClick={(e)=>{ propsData.saveType ==='Update' ? propsData.updateFn(e)  :   propsData.reqData(e)}}  >
         {propsData.saveType}
        </Button>

        {propsData.saveType ==='Update' ? <Button variant="danger" className='ms-2' onClick={()=>{propsData.deleteFn()}}>
         Delete
        </Button> : null }
        
        </Form.Group>

        </Form>
  </>)
}