import React from 'react'
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';


export const SweetAlert = (props) => {
        swal({
            title: props.title,
            text: props.msg,
            icon: props.icon,
            button: props.btn,
            dangerMode : props.dangerMode
          })
}

