import React, { useEffect, useRef, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Index from './views/pages/homepage/Index';
import About from './views/pages/about/About';
import Contact from './views/pages/contact/Contact';
import Service from './views/pages/service/Service';
import Portfolio from './views/pages/portfolio/Portfolio';

import Menu from './views/pages/menu/Menu';
import './views/pages/css/main.css';
import Blogs from './views/pages/blogs/Blogs';
import BlogDetail from './views/pages/blogs/BlogDetail';
import ScrollToTop from './views/ScrollToTop';
import Dashboard from './views/cms/page/dashboard/Dashboard';
import Login from './views/cms/page/login/Login';
import Registration from './views/cms/page/login/Registration';
import Error from './views/pages/404error/Error';
import Sidebar from './views/cms/page/sidebar/Sidebar';
import AdminHome from './views/cms/page/dashboard/AdminHome';
import AdminAbout from './views/cms/page/dashboard/AdminAbout';
import AdminBlogs from './views/cms/page/dashboard/AdminBlogs';
import AdminService from './views/cms/page/dashboard/AdminService';
import AdminPortfolio from './views/cms/page/dashboard/AdminPortfolio';
import AdminGallery from './views/cms/page/dashboard/AdminGallery';
import AdminProfile from './views/cms/page/dashboard/AdminProfile';
import AdminResetPass from './views/cms/page/dashboard/AdminResetPass';
import AdminSettings from './views/cms/page/dashboard/AdminSettings';
import AdminDeveloper from './views/cms/page/dashboard/AdminDeveloper';
import AdminContact from './views/cms/page/dashboard/AdminContact';
import ForgetPass from './views/cms/page/login/ForgetPass';
import ResetPassword from './views/cms/page/login/ResetPassword';
import AddBlog from './views/cms/page/dashboard/blog/AllBlog';
import AddCategory from './views/cms/page/dashboard/blog/AddCategory';
import AdminBlogDetail from './views/cms/page/dashboard/blog/AdminBlogDetail';
import AllBlog from './views/cms/page/dashboard/blog/AllBlog';
import AdminCommunity from './views/cms/page/dashboard/AdminCommunity';
import PrivateComponent from './PrivateComponent';
import Cursor from './views/pages/cursor/Cursor';
import { createContext } from 'react';
import { sitesettings } from './views/pages/apiservice/Service.api';

const App = () => {



  const location  = useLocation();
  useEffect(() => {
    console.log(location.pathname)
  }, [location])

  return (
    <>

{
  location.pathname.includes('/admin') ? <CMSRouter/> : location.pathname.includes('/login')  || location.pathname.includes('/register') || location.pathname.includes('forget-password')  || location.pathname.includes(`reset-password`)?   <CMSLog/> : <PageRouter />
}

    
   
    

    </>
  )
}

export default App



export const GlobalData = createContext();

export const PageRouter  = () =>{

  let [siteData , setSiteData] = useState([]);

  const sitedataapi = async ()=>{
    let res = await sitesettings();
    setSiteData(res.data);
  }

  useEffect(()=>{
    sitedataapi()
  }, [])


 return(<>

<GlobalData.Provider value={{data:{siteData}}}>
      <Cursor/>
   <Menu>
  <Routes>
    <Route path='/' exact element = {<Index/>} />
    <Route path='/about' element = {<About/>} />
    <Route path='/contact' element = {<Contact/>} />
    <Route path='/services' element = {<Service/>} />
    <Route path='/portfolio' element = {<Portfolio/>} />
    <Route path='/blogs' element = {<Blogs/>} />
    <Route path='/blogs-details/:id' element = {<BlogDetail/>} />
    <Route path='*' element = {<Error/>} />
  </Routes>
  </Menu>

</GlobalData.Provider>

  
 </>)
}


export const CMSRouter  = () =>{
 return(<>

   
   <Sidebar>
   <Routes>
   <Route element={<PrivateComponent/>}>
    <Route path='/admin/dashboard' element={<Dashboard/>} />
    <Route path='/admin/home' element={<AdminHome/>} />
    <Route path='/admin/about-us' element={<AdminAbout/>} />
    <Route path='/admin/blogs' element={<AdminBlogs/>} />
    <Route path='/admin/service' element={<AdminService/>} />
    <Route path='/admin/portfolio' element={<AdminPortfolio/>} />
    <Route path='/admin/gallery' element={<AdminGallery/>} />
    <Route path='/admin/profile' element={<AdminProfile/>} />
    <Route path='/admin/change-password' element={<AdminResetPass/>} />
    <Route path='/admin/settings' element={<AdminSettings/>} />
    <Route path='/admin/devloper' element={<AdminDeveloper/>} />
    <Route path='/admin/contact' element={<AdminContact/>} />
    <Route path='/admin/blog-detail' element={<AdminBlogDetail/>} />
    <Route path='/admin/all-blogs' element={<AllBlog/>} />
    <Route path='/admin/blog-category' element={<AddCategory/>} />
    <Route path='/admin/cms-community' element={<AdminCommunity/>} />




    <Route path='/admin/*' element = {<Error/>} />
    </Route>
    </Routes>
</Sidebar>





 </>)
}

export const CMSLog  = () =>{
 return(<>


<Routes>
<Route path='/login' element={<Login/>} />
<Route path='/register' element={<Registration/>} />
<Route path='/forget-password' element={<ForgetPass/>} />
<Route path='reset-password/:token' element={<ResetPassword/>} />


</Routes>


 </>)
}