import About from "../about/About";
import Blogs from "../blogs/Blogs";
import Contact from "../contact/Contact";
import Index from "../homepage/Index";

import Portfolio from "../portfolio/Portfolio";
import Service from "../service/Service";

export const MenuData = [
    { 
        id : 1,
        title : 'Home',
        path : '/',
        component : <Index/>
    },
    { 
        id : 2,
        title : 'About',
        path : '/about',
        component : <About/>
    },
    { 
        id : 3,
        title : 'Service',
        path : '/services',
        component : <Service/>
    },
    { 
        id : 4,
        title : 'Portfolio',
        path : '/portfolio',
        component : <Portfolio/>
    },
    { 
        id : 5,
        title : 'Blogs',
        path : '/blogs',
        component : <Blogs/>
    },
    { 
        id : 6,
        title : 'Contact',
        path : '/contact',
        component : <Contact/>
    },
]