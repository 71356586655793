import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import MyHelmet from '../../../pages/helmet/MyHelmet'
import { LoginApi } from '../../api/Service.dashboard'
import { SweetAlert } from '../alert/SweetAlert'

const Login = () => {

  const [user,setUser] = useState();
  const [email,setemail] = useState();
  const [password,setpassword] = useState();
  const [rem,setrem] = useState(false);

  let navigate= useNavigate();


  const loginFn = async (e) =>{
   try{
    console.log('clicked')
    e.preventDefault();
      let res = await LoginApi({email,password,rem});
      
      if(res.status === true){
        SweetAlert({title:'Login Status' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
        setUser(res.data);  

        localStorage.setItem('cmsauth' , res.token);
        localStorage.setItem('cmsuid' , res.userID);
        navigate('/admin/dashboard')
        
        
      }
   }
   catch(err){
    SweetAlert({title:'Login Status' , msg : 'failed' , icon : 'warning', btn : 'close', dangerMode: true})
   }
  } 
  return (<>

<MyHelmet meta_title="Admin Login"  />

  
 <Container fluid className='log-container'>
  <Row className='p-3 h-100'>

    <div className="log-card d-flex justify-content-center align-items-center">
    <Col md={3} className='card-body-w box-s m-auto'>
      <Form onSubmit={loginFn}>
        <Form.Group className='my-3 m-auto d-flex justify-content-center'>
          <img src='/images/logo1.png' height='80px' width='250px' className='m-auto' />
        </Form.Group>

        <Form.Group className='my-3  d-flex justify-content-center align-items-center flex-column text-center'>
        <Form.Label > <h4>Hi, Welcome Back </h4> 
        <p>Enter your credentials to continue</p> 
        </Form.Label>
        </Form.Group>
        
        <Form.Group className='form-group my-3 '>
        <Form.Label > Email </Form.Label>
        <Form.Control  type="text" onChange={(e)=>{setemail(e.target.value)}} />
        </Form.Group>

        <Form.Group className='form-group my-3 '>
        <Form.Label > Password </Form.Label>
        <Form.Control  type="text" onChange={(e)=>{setpassword(e.target.value)}} />
        </Form.Group>
        <Form.Group className="my-4 d-flex justify-content-between">
        <Form.Check type="checkbox" label="Remember me" onChange={()=>{setrem(!rem)}} />
        <NavLink className='link-tag' to='/forget-password'>Forget Password</NavLink>
      </Form.Group>

      <Form.Group className="my-4 d-flex justify-content-between">
        <Button className='btn-cms w-100' type='submit'>Login</Button>
      </Form.Group>
      </Form>
        <hr />
      <Form.Group className="my-4 d-flex justify-content-between">
      <Form.Label className='cursor-pointer mx-auto'> <NavLink className='link-tag' to='/register'>Don't have account?</NavLink>  </Form.Label>
      </Form.Group>


     </Col>
    </div>
 
  </Row>
 </Container>
  
  
  </>)
}

export default Login