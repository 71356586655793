import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import MyHelmet from '../helmet/MyHelmet'

const Error = () => {
  return (
    <>

<MyHelmet meta_title='404 Error' meta_description="page not found" meta_keyword='' />
    <Row>

    <Col>
        <h1 className='text-white'>404 Error</h1>
        <NavLink to='/' className='text-decoration-none' >Go Home</NavLink>
    </Col>

    </Row>
    
    
    </>
  )
}

export default Error