import React from 'react'
import { Navigate , Outlet } from 'react-router-dom'

const PrivateComponent = () => {

    let Auth = localStorage.getItem('cmsauth');
    let UID = localStorage.getItem('cmsuid');
  return (<>
  
{ Auth ?  <Outlet/> :  <Navigate to='/login' />}


  
  
  </>)
}

export default PrivateComponent