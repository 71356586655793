import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../css/main.css'
import { TagsInput } from "react-tag-input-component";
import ImageUploading from "react-images-uploading";
import { Col, Container, Form } from 'react-bootstrap';
import { BlogCategoryGet, BlogContentPost } from '../../../api/Service.dashboard';
import { SweetAlert } from '../../alert/SweetAlert';

const AdminBlogDetail = () => {
  let formRef  = useRef();
    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
  
    const onChange = (imageList, addUpdateIndex) => {
    // data for submit
      console.log(imageList, addUpdateIndex);
      setImages(imageList);
    };

    let submitBlog = (e)=>{
        e.preventDefault();
    }


    /* api call */
    const [CategoryData, setCategoryData] = useState([]);
    const apiCategory =  async () =>{
      let res = await BlogCategoryGet();
      setCategoryData(res.data)
    }
    useEffect(()=>{
      apiCategory();
    },[])


    const [blogCollect , setblogCollect] = useState({
      title : '',
      category : '',
      tags : '',
      userID : '',
      slug : '',
      meta_title : '',
      meta_keyword : '',
      meta_discription : ''
    })

    const handelBlogInp = (e)=>{
      let name = e.target.name;
      let type = e.target.type;
      let value;
      if(type === 'file') {
        value = e.target.files[0];
      }
      else{
        value = e.target.value;
      }
      setblogCollect({...blogCollect , [name]:value})
    }
  

  //   const getData = () =>{
  //     console.log(data, blogCollect, keywa, blogTags,'==============')
  // }



                /* api call */
     
                const apiBlogPost =  async (data,keywa,blogTags) =>{
                  console.log( blogCollect,data,keywa,blogTags , images[0].file, '=============')
                  let res = await BlogContentPost(blogCollect,data,keywa,blogTags , images[0].file);
                  if(res.status===true){
                    SweetAlert({title:'Blog Creation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
                    formRef.current.reset();
                    setImages(null);
                  }
                }
              

  return (<>
  
  
  <Container fluid className="">

        
          <Form action="" className='row' onSubmit={submitBlog} ref={formRef}>

          <Col md={8} className=" ">
          <AddNewBlog category={CategoryData} fillValue = {handelBlogInp} rsData={apiBlogPost} />
          </Col>

          <Col  md={4} className=" ">
          
          <div className="card-body-w box-s">
          <p>Feature Image <small className="text-danger">(required*)</small></p>
          <hr />

      <ImageUploading
        
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button className='btn-cms'
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Upload
            </button>
            &nbsp;


            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button className='btn-del' onClick={() => onImageRemove(index)}><i class="fa-regular fa-trash-can"></i></button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>

          </div>
          </Col>

          </Form>

    </Container>
  
  
  </>)
}

export default AdminBlogDetail;


export const AddNewBlog = (props) =>{

      const [value, setValue] = useState('');
    const [selected, setSelected] = useState(["school"]);

    const [blogTags, setblogTags] = useState([]);

    let runFn = () =>{
      props.rsData(value , selected , blogTags)
    }

    
  return(<>
              <div className="card-body-w box-s">
            <h6>Blog Content</h6>
            <hr />

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Title</label>
              <input type="text" id="" className='form-control rounded-1' name='title'  onChange={props.fillValue} />
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Category</label>
              <select id="" className="form-select rounded-1" name='category' onChange={props.fillValue}>
                <option value="" selected disabled> -- select --</option>
                
                {
                  props.category?.map((val)=>{
                    return(<>
                      <option className='text-capitalize' value={val.title}>{val.title}</option>
                    </>)
                  })
                }

              </select>
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Content</label>
              <ReactQuill modules={quill_modules} theme="snow" value={value} onChange={setValue} className="rounded-1"   />
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Tags</label>
              <TagsInput
        value={blogTags}
        onChange={setblogTags}
        name="meta_keyword"
        placeHolder="enter keywords"

      />
            </div>


            <div className="form-group my-3">
             <button className='btn-cms' onClick={()=>{runFn()}}>Save</button>
             </div>

          </div>

          <div className="card-body-w box-s my-5">
            <h6>Seo Content</h6>
            <hr />

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">meta title</label>
              <input type="text" id="" className='form-control rounded-1' name='meta_title' onChange={props.fillValue} />
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">meta description</label>
              <input type="text" id="" className='form-control rounded-1' name='meta_discription' onChange={props.fillValue} />
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">meta keyword</label>
              <TagsInput
        value={selected}
        onChange={setSelected}
        name="meta_keyword"
        placeHolder="enter keywords"

      />
            </div>
          </div>
  </>)
}


export const quill_modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image", "video"],
    ["clean"]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};