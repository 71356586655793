import React from 'react'
import Index from '../breadcrumb/Index';

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Map from '../maps/Map';
import GoogleMaps from '../maps/Map';
import { useRef } from 'react';
import { contactpage } from '../apiservice/Service.api';
import { SweetAlert } from '../../cms/page/alert/SweetAlert';


import { useContext } from 'react';
import { GlobalData } from '../../../App';
import MyHelmet from '../helmet/MyHelmet';
const Contact = () => {

  const siteglobaldata = useContext(GlobalData);


  console.log(siteglobaldata.data.siteData.site_gmap

    , 'siteglobaldatasiteglobaldata')
  let formRef = useRef()
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    contactpageApi(ContactData);
  
  };

  


  const [ContactData, setContactData] = useState({
    name: '',
    email : '',
    subject : '',
    discription : ''
  })

  const handelContactInp = (e) =>{
    console.log('called' , e)
    let name = e.target.name;
    let value = e.target.value;
    setContactData({...ContactData , [name]: value})
   }


   const contactpageApi =  async (ContactData) =>{
    console.log(ContactData , 'ContactDataContactData')
     let res = await contactpage(ContactData);
     if(res.status===true){
      SweetAlert({title:'Message Status' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});

     formRef.current.reset()
    }
   }




  return (<>
        <MyHelmet meta_title='Contact us'/>

    <Index page='Contact' />
    <div className="about-wrapper contact-wrapper">
    <div className="about-title-box">
      <h2 className='mb-5'>Contact</h2>
    </div>
    <div className="map-box">
    {
           <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src='https://maps.google.com/maps?width=100%&amp;height=400&amp;hl=en&amp;q=%20Rahul%20furniture%20industry+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'></iframe>
    }
    </div>
    
<Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
  
      <Row className="mt-5">
        
        <Form.Group className='my-3 col-md-6' controlId="validationCustom03">
          <Form.Control type="text" placeholder="Name" className='form-inp bg-transparent rounded-0 border-light' required name='name' onChange={handelContactInp} />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='my-3 col-md-6' controlId="validationCustom03">
          <Form.Control type="email" placeholder="Email" className='form-inp bg-transparent rounded-0 border-light' required name='email' onChange={handelContactInp} />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='my-3' controlId="validationCustom03">
          <Form.Control type="text" placeholder="Subject" className='form-inp bg-transparent rounded-0 border-light' required name='subject' onChange={handelContactInp} />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='my-3' controlId="validationCustom03">
           
          <Form.Control as="textarea" rows={5}  placeholder="Message" className='textarea-inp bg-transparent rounded-0 border-light' required name='discription' onChange={handelContactInp} />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>
      
      </Row>
      <Form.Group className="mb-3">
        <Form.Check
          required
          className='check-inp'
          label="Agree to terms and conditions"
          feedback="You must agree before submitting."
          feedbackType="invalid"
          
        />
      </Form.Group>
      <Button type="submit" className='btn-all'>Submit form</Button>
    </Form>
    


    </div>
  
  
  </>)
}

export default Contact