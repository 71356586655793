import React, { useEffect, useState } from 'react';
import { Row,Col } from 'react-bootstrap'
import { statsGet } from '../../api/Service.dashboard';
import Sidebar from '../sidebar/Sidebar'

const Dashboard = () => {

              /* api call */
              const [statsData, setstatsData] = useState([]);
              const [statsDownload, setstatsDownload] = useState([]);

              const apistats =  async () =>{
                let res = await statsGet();
                setstatsData(res.data);
                setstatsDownload(res.cms_downlaod)
              }
              useEffect(()=>{
                apistats();
              },[])
 
              

  return (
    <>


    <Row className='admin-dashboard-container'>
      <Col md={4} className='state-col' >
       <div className="card-body stats-card">
        <div className="iconsBox">
        <i class="fa-solid fa-chart-simple"></i>
        </div>
        <div className="paraBox">
          <h4 className='state-number'>{statsData.visitor || '000'}</h4>
          <small className="state-title">Website Visited</small>
        </div>
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>

       </div>
      </Col>
      <Col md={4} className='state-col' >
       <div className="card-body stats-card">
        <div className="iconsBox">
        <i class="fa-solid fa-chart-simple"></i>
        </div>
        <div className="paraBox">
          <h4 className='state-number'>9000</h4>
          <small className="state-title">lorem</small>
        </div>
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>
       </div>
      </Col>
      <Col md={4} className='state-col' >
       <div className="card-body stats-card">
        <div className="iconsBox">
        {/* <img src="/images/cms_download.svg" alt="" /> */}
        <i class="fa-solid fa-download"></i>
        </div>
        <div className="paraBox">
          <h4 className='state-number'>{statsDownload}</h4>
          <small className="state-title">CMS Download</small>
        </div>
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>
       </div>
      </Col>
    </Row>
    
    </>
  )
}

export default Dashboard