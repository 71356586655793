import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import MyHelmet from '../../../pages/helmet/MyHelmet';
import { RegisterApi } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';


const Registration = () => {

let navigate = useNavigate();
  
  const [user,setUser] = useState();

  const [email,setemail] = useState();
  const [password,setNewpassword] = useState();
  const [cpassword,setcpassword] = useState();
  const [secretkey,setsecretKey] = useState();


  const registerFn = async (e) =>{

  try{  console.log(email,password,cpassword,secretkey , 'email,password,cpassword,secretkey')
    e.preventDefault();
      let res = await RegisterApi({email,password,cpassword,secretkey});
     

      if(res.status === true){
        SweetAlert({title:`Account Created, Activation Key : [ ${res.activation_key} ] , please note and update to .env file` , msg : "success" , icon : 'warning', btn : 'close', dangerMode: true})
        setUser(res.data); 
        navigate('/login') 
      }

      console.log(res.data , 'responseresponse')
    
    }
    catch(err){
      SweetAlert({title:'Account Registration' , msg : 'failed' , icon : 'warning', btn : 'close', dangerMode: true})
     }
  } 


  return (<>
<MyHelmet meta_title="Admin Registration"  />
  
  <Container fluid className='log-container'>
  <Row className='my-auto h-100 p-3'>
  <div className="log-card d-flex justify-content-center align-items-center">
  <Col md={4} className='card-body-w box-s m-auto'>
      <Form onSubmit={registerFn}>
        <Form.Group className='my-3 m-auto d-flex justify-content-center'>
          <img src='/images/logo1.png' height='80px' width='250px' className='m-auto' />
        </Form.Group>

        <Form.Group className='my-3  d-flex justify-content-center align-items-center flex-column text-center'>
        <Form.Label > <h4>Sign Up</h4> 
        </Form.Label>
        </Form.Group>
        
        <Form.Group className='form-group my-3 '>
        <Form.Label > Email </Form.Label>
        <Form.Control  type="text"  onChange={(e)=>{setemail(e.target.value)}} />
        </Form.Group>

        <Form.Group className='form-group my-3 '>
        <Form.Label > Password </Form.Label>
        <Form.Control  type="text"  onChange={(e)=>{setNewpassword(e.target.value)}} />
        </Form.Group>

        <Form.Group className='form-group my-3 '>
        <Form.Label >Confirm Password </Form.Label>
        <Form.Control  type="text"  onChange={(e)=>{setcpassword(e.target.value)}} />
        </Form.Group>

        <Form.Group className='form-group my-3 '>
        <Form.Label >Secret Key </Form.Label>
        <Form.Control  type="text"  onChange={(e)=>{setsecretKey(e.target.value)}} />
        </Form.Group>

        <Form.Group className="my-4 d-flex justify-content-between">
        <Form.Check type="checkbox" label="Agree with all Terms & Condition." required />
      </Form.Group>

      <Form.Group className="my-4 d-flex justify-content-between">
        <Button className='btn-cms w-100' type='submit'>Register</Button>
      </Form.Group>
      </Form>
        <hr />
      <Form.Group className="my-4 d-flex justify-content-between">
      <Form.Label className='cursor-pointer mx-auto'> <NavLink className='link-tag'  to='/login'>Already have account?</NavLink> </Form.Label>
      </Form.Group>


     </Col>
     </div>
  </Row>
 </Container>
  
  
  </>
  )
}

export default Registration