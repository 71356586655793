
import { Col,Row, Container, Form } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { SweetAlert } from '../alert/SweetAlert';
import { siteCommuintyMyPostGet, siteCommuintyPageGet, siteCommuintyPostDelete, siteCommuintyPostGet, siteCommuintyPostPost, siteCommuintyPostupvote} from '../../api/Service.dashboard';
import Spinner from 'react-bootstrap/Spinner';

import Accordion from 'react-bootstrap/Accordion';
import { quill_modules } from './blog/AdminBlogDetail';


const AdminCommunity = () => {

    const [show, setShow] = useState(true);
    const [fpost, setfpost] = useState(false);


      
           /* api call */
           const [CommuintyPostData, setCommuintyPostData] = useState([]);

           const apisiteCommuintyPostGet =  async () =>{
            let res = await siteCommuintyPostGet();
            setCommuintyPostData(res.data)
        
           }
           useEffect(()=>{
             apisiteCommuintyPostGet();
           },[])


           const [CommuintyPageData, setCommuintyPageData] = useState([]);
           const apisiteCommuintyPageGet =  async () =>{
            let res = await siteCommuintyPageGet();
            setCommuintyPageData(res.data[0])
            console.log(res.data , 'CommuintyPage')
           }
           useEffect(()=>{
             apisiteCommuintyPageGet();
           },[])




           const [collectData , setCollectData] = useState({
            post_title : "",
            post_discription : "",
            post_image : ""
          })
      
          const handelSiteInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectData({...collectData , [name] : value});
          }





  return (
    <>


    <Container className='commnunity-container'>
        <Row>
            <Col xs={12}>
           {CommuintyPageData.state===true ?  <Alert show={show} variant="success">
        <Alert.Heading>{CommuintyPageData.title}</Alert.Heading>
        <p>
        {CommuintyPageData.paragraph}
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-success">
            Close
          </Button>
        </div>
      </Alert> : null }
            </Col>


            <Col>
            <div className="commnunity-tab-wrapper">

            <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className=" commnunity-tab"
    >
      <Tab eventKey="home" title="All Post">

      {
        CommuintyPostData?.map((val)=>{
          return(<>
            <CommnunityPost id={val._id} avatar={val.posted_by[0].image} name={val.posted_by[0].name} up={val.upvote.length}  degination={val.posted_by[0].degination} date={val.createdAt} verify={val.posted_by[0].verified} postImg={val.post_image} title={val.post_title} para={val.post_discription} />
          </>)
        })
      }
     
      </Tab>
      <Tab eventKey="profile" title="Trending">
      {
        CommuintyPostData?.map((val)=>{
          return(<>
            <CommnunityPost id={val._id} avatar={val.posted_by[0].image} name={val.posted_by[0].name} up={val.upvote.length}  degination={val.posted_by[0].degination} date={val.createdAt} verify={val.posted_by[0].verified} postImg={val.post_image} title={val.post_title} para={val.post_discription}  />
          </>)
        })
      }

      </Tab>

      <Tab eventKey="mypost" title="My Posts">
        <MyCommnunityPost callAllPost={apisiteCommuintyPostGet} />
      </Tab>
  
    </Tabs>

    </div>
            </Col>



     
        </Row>

    </Container>
    
    </>
  )
}

export default AdminCommunity


export const CommnunityPost = (props) =>{
console.log(props , 'mmmamsamprops')

  const callvote = async (bid) =>{
    console.log('clickede')
    console.log(bid)
   let res = await siteCommuintyPostupvote(bid);

   if(res.status===true){
    props.callAllPost();
   }
  }


    return(<>
      <Col className='mb-2'>
            <div className="card-body post-body">
                <div className="doc-1">
                <div className="imgBox">
                    <img src={props.avatar || `/images/noimg.png`} alt="" />
                </div>
                <div className="userBox">
                    <h4>{props.name} <div className="badge">{props.verify ? <img src="/images/verify.png" alt="" className='rounded-circle' /> : null}</div> </h4>
                    <p >{props.degination}</p>
                    <small>{props.date}</small>
                </div>
                

                <div className="vote-box ms-auto">
                <small onClick={()=>{callvote(props.id)}}>{props.up} <i class="fa-solid fa-thumbs-up"></i> upvote</small>
                {/* <small className='ms-3'>{props.down} <i class="fa-solid fa-thumbs-down"></i> downvote</small>  */}

                {props.delete===true  ? <small className='ms-3 text-danger cursor-pointer' onClick={()=>{props.postdelete(props.id)}}><i class="fa-solid fa-trash"></i> Delete</small>  : null}

                </div>
                </div>

                <div className="dov">
                    <strong>{props.title}</strong>
                    <small dangerouslySetInnerHTML={{ __html:props.para }} ></small>
                </div>

                <div class="col-lg-4 col-6 thumb my-2">
                <a href={props.postImg} class="fancybox" data-fancybox="gallery">
                  <img src={props.postImg  || `/images/pf.jpg`}class="img-fluid zoom w-100 transition" alt=""  style={{"object-fit": "cover", 'maxHeight' : '80px', 'maxWidth' : '80px'}} />
                </a>
              </div>

            </div>
        </Col>
    </>)
}



export const MyCommnunityPost = (props) =>{

 

  const [MyCommuintyPostData, setMyCommuintyPostData] = useState([]);
  const apisiteCommuintyPostMyGet =  async () =>{
   let res = await siteCommuintyMyPostGet();
   setMyCommuintyPostData(res.data)
   console.log(res.data , 'CommuintyPost my post')
  }
  useEffect(()=>{
    apisiteCommuintyPostMyGet();
  },[])



  let formRef = useRef()
  let formImgRef = useRef()

  const [Discription ,setDiscription ] = useState();
  const [Title ,setTitle ] = useState();
  const [NewImg ,setNewImg ] = useState();

  const [Image ,setImage ] = useState();


  
  const apiCommuintyPostPost =  async (e) =>{
      
    e.preventDefault();

    let res = await siteCommuintyPostPost({Title,Discription,Image});
    console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    if(res.status === true){
      SweetAlert({title:'Post Added' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
      }
      formRef.current.reset();
      props.callAllPost();
      apisiteCommuintyPostMyGet();

   }

   const apiCommuintyPostDelete =  async (id) =>{
    let res = await siteCommuintyPostDelete(id);
    console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    if(res.status === true){
      SweetAlert({title:'Deletion' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
      }
      props.callAllPost();
      apisiteCommuintyPostMyGet();
   }


  // setNewImg(URL.createObjectURL(Image))
  return(<>

<Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header><i class="fa-solid fa-pen me-2"></i> write a post</Accordion.Header>
        <Accordion.Body className='bg-light'>
        <Row>
          <Col>
          <Form onSubmit={apiCommuintyPostPost} ref={formRef}>
        <Form.Group className='mb-3'>
          <Form.Label>Title</Form.Label>
          <Form.Control type='text' placeholder='Headline' className='rounded-1' onChange={(e)=>{setTitle(e.target.value)}} required />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Image</Form.Label>
          <Form.Control ref={formImgRef} type='file' placeholder='Headline' className='rounded-1' onChange={(e)=>{setImage(e.target.files[0]) ;setNewImg(URL.createObjectURL(e.target.files[0])) }} />

          <div className="form-img-container my-2">
            <img src={NewImg || `/images/noimg.png`} alt="" className='image-uploaded' height='100px' width='100px' />
          </div>

        </Form.Group>

        <Form.Group className='mb-3' >
          <ReactQuill modules={quill_modules} theme="snow" value={Discription}  onChange={setDiscription}  className="rounded-1 bg-white"   />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Button type='submit'>Post</Button>
        </Form.Group>
          
          </Form>

          </Col>
        </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>


  


        <Row>
        <Col sm={12} className='my-3 py-2 bg-success text-white'><strong>My Recent Post</strong></Col>
        <Col>

        { 
          MyCommuintyPostData.length <=0 ? <small>You haven't post anything</small> : 
          MyCommuintyPostData?.map((val)=>{
          return(<>
            <CommnunityPost id={val._id} postdelete={apiCommuintyPostDelete} avatar={val.posted_by[0].image || `./images/user.png`} delete={true} name={val.posted_by[0].name} up={val.upvote.length}  degination={val.posted_by[0].degination} date={val.createdAt} verify={val.posted_by[0].verified} postImg={val.post_image} title={val.post_title} para={val.post_discription} />
          </>)
        })
      }
        </Col>
        </Row>
  </>)
}

