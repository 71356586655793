import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../css/main.css';

import ImageUploading from "react-images-uploading";
import { siteSettingsGet, siteSettingsPut } from '../../api/Service.dashboard';
import { SweetAlert } from '../alert/SweetAlert';
import { Row } from 'react-bootstrap';

const AdminSettings = () => {

  const [value, setValue] = useState('');
  const [selected, setSelected] = useState(["school"]);
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  // const onChange = (imageList, addUpdateIndex) => {
  //   // data for submit
  //   console.log(imageList, addUpdateIndex);
  //   setImages(imageList);
  // };

  let submitBlog = (e)=>{
      e.preventDefault();
  }


  
           /* api call */
           const [SiteSetData, setSiteSetData] = useState([]);
           const apisiteGet =  async () =>{
            let res = await siteSettingsGet();
            setSiteSetData(res.data)

            console.log(res.data , 'SiteSetDataSiteSetData')
           }
           useEffect(()=>{
             apisiteGet();
           },[])


           const [collectData , setCollectData] = useState({
            site_title : '',
            site_discription : '',
            site_estd : '',
            site_phone : '',
            site_address : '',
            site_email : '',
            site_gmap : '',
            site_copyright_name : '',
            site_copyright_link : '',
            site_font_family : '',
            site_font_color : '',
            site_theme_color1 : '',
            site_theme_color2 : '',
            site_theme_color3 : '',
            site_theme_color4 : '',
            site_favicon : '',

          })
      
          const handelSiteInp = (e) =>{
            let type = e.target.type ;
            let name = e.target.name ;
            let value;
            if(type==='file'){
              value = e.target.files[0] ;
            }
            else{
              value = e.target.value;
            }
            setCollectData({...collectData , [name] : value});
          }


          const apiSitePut =  async (e) =>{
            console.log('clicked')
            e.preventDefault();
            // let fav = null;
            // if(images[0].file){
            //   fav = images[0].file
            // }
            // console.log(fav)
            let res = await siteSettingsPut(collectData);
            console.log(res, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            if(res.status === true){
              SweetAlert({title:'Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
              }

              apisiteGet();
    
           }




  return (<>
  

      <div className="row block1">
        
          <form className='row' onSubmit={apiSitePut}>

          <div className="col-12">
          
          <div className="card-body-w box-s">
          <p>Site Settings</p>
          <hr />

          <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Site title</label>
              <input type="text"  className='form-control rounded-1' name='site_title' onChange={handelSiteInp} defaultValue={SiteSetData.site_title} />
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Site discription</label>
              <textarea  cols="30" rows="5"  className='form-control rounded-1' name='site_discription' onChange={handelSiteInp} defaultValue={SiteSetData.site_discription}></textarea>
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Site Est.</label>
              <input type="date"   className='form-control rounded-1' name='site_estd' onChange={handelSiteInp} defaultValue={SiteSetData.site_estd} />
            </div>
          </div>

          <div className="card-body-w box-s my-3">
          <p>Contact Information</p>
          <hr />

          <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Phone</label>
              <input type="text"  className='form-control rounded-1' name='site_phone' onChange={handelSiteInp} defaultValue={SiteSetData.site_phone} />
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Address</label>
              <textarea  cols="30" rows="1"  className='form-control rounded-1' name='site_address' onChange={handelSiteInp} defaultValue={SiteSetData.site_address}></textarea>
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Email</label>
              <input type="email"   className='form-control rounded-1' name='site_email' onChange={handelSiteInp} defaultValue={SiteSetData.site_email} />
            </div>
          </div>

          <div className="card-body-w box-s my-3">
          <p>Google Information</p>
          <hr />
            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Map Iframe</label>
              <textarea  cols="30" rows="1"  className='form-control rounded-1' name='site_gmap' onChange={handelSiteInp} defaultValue={SiteSetData.site_gmap}></textarea>
            </div>
          </div>



          <div className="card-body-w box-s my-3">
          <p>Header / Footer Information</p>
          <hr />
       
          <Row>
            <div className="form-group col-md-6 my-3">
            <input type='file' className='form-control' name='site_favicon' onChange={handelSiteInp} />
          {/* <ImageUploading
        
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button className='btn-cms'
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Upload Fav Icon
            </button>
            &nbsp;


            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button className='btn-del' onClick={() => onImageRemove(index)}><i class="fa-regular fa-trash-can"></i></button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading> */}
      </div>

      <div className="form-group col-md-6 my-3">
      <p>Fav Icon in use</p>
              <img src={SiteSetData.site_favicon} alt="img"  height='100px' width='100px' />
      </div>
</Row>

      <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Copyright Text</label>
              <textarea  cols="30" rows="1"  className='form-control rounded-1' name='site_copyright_name' onChange={handelSiteInp} defaultValue={SiteSetData.site_copyright_name} disabled></textarea>
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Copyright Link</label>
              <textarea  cols="30" rows="1"  className='form-control rounded-1' name='site_copyright_link' onChange={handelSiteInp} defaultValue={SiteSetData.site_copyright_link} disabled></textarea>
            </div>
      

            <div className="form-group my-3">
              <button className="btn-cms" type='submit'>Save</button>
            </div>

          </div>

          </div>
          </form>
        
      </div>

  
  
  </>)
}

export default AdminSettings