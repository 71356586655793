import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const SeoForm = (props) => {
  return (<>
  
  <Form>

  <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Slug</Form.Label>
        <Form.Control type="text" className='rounded-1' defaultValue={props.slug} name="slug"  onChange={props.seoFn} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Meta Title</Form.Label>
        <Form.Control type="text" className='rounded-1' defaultValue={props.metaTitle} name="meta_title"  onChange={props.seoFn} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Meta Description</Form.Label>
        <Form.Control type="text" className='rounded-1' defaultValue={props.metaDisc}  name="meta_discription" onChange={props.seoFn} />
      </Form.Group>
  
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Meta Keyword</Form.Label>
        <Form.Control type="text" className='rounded-1' defaultValue={props.metaKey} name="meta_keyword" onChange={props.seoFn} />
      </Form.Group>

    </Form>
  
  
  </>)
}

export default SeoForm