

export const CMSMenuData = [
    { 
        id : 1,
        title : 'Dashboard',
        path : '/admin/dashboard',
        icon : <i class="fa-solid fa-gauge"></i> ,
    },
    { 
        id : 2,
        title : 'Home',
        path : '/admin/home',
        icon : <i class="fa-sharp fa-solid fa-house"></i>,
    },
    { 
        id : 3,
        title : 'About',
        path : '/admin/about-us',
        icon : <i class="fa-solid fa-id-card-clip"></i>,
    },
    { 
        id : 4,
        title : 'Service',
        path : '/admin/service',
        icon : <i class="fa-sharp fa-solid fa-box-open"></i>,
    },
    { 
        id : 5,
        title : 'Portfolio',
        path : '/admin/portfolio',
        icon : <i class="fa-solid fa-address-card"></i>,
    },
    { 
        id : 6,
        title : 'Blogs',
        path : '/admin/blogs',
        icon : <i class="fa-solid fa-file-circle-exclamation"></i>,
        submenu : [{
            id : 1,
            title : 'All Blogs',
            path : '/admin/all-blogs',
            icon : <i class="fa-solid fa-file-circle-plus"></i>,
        },
        {
            id : 2,
            title : 'Add New Blog',
            path : '/admin/blog-detail',
            icon : <i class="fa-solid fa-file-circle-plus"></i>,
        },
        {
            id : 3,
            title : 'Category',
            path : '/admin/blog-category',
            icon : <i class="fa-solid fa-file-circle-plus"></i>,
        }]
    },
    { 
        id : 7,
        title : 'Gallery',
        path : '/admin/gallery',
        icon : <i class="fa-solid fa-image"></i>,
    },
    { 
        id : 8,
        title : 'Contact',
        path : '/admin/contact',
        icon : <i class="fa-solid fa-address-book"></i>,
    },
    { 
        id : 9,
        title : 'Settings',
        path : '/admin/settings',
        icon : <i class="fa-solid fa-gear"></i>,
    },
    { 
        id : 10,
        title : 'Developer Tools',
        path : '/admin/devloper',
        icon :  <i class="fa-solid fa-gears"></i>,
    },
    { 
        id : 11,
        title : 'CMS Community',
        path : '/admin/cms-community',
        icon :  <i class="fa-solid fa-globe"></i>,
    }
]