import React, { useEffect, useRef, useState } from 'react'
import {Container, Col, Row, Form, Button , Modal, Accordion } from 'react-bootstrap';
import { BlogCategoryGet, BlogContentDelete, BlogContentGet, BlogContentPut } from '../../../api/Service.dashboard';
import { SweetAlert } from '../../alert/SweetAlert';
import { AddNewBlog, quill_modules } from './AdminBlogDetail';
import ImageUploading from "react-images-uploading";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../css/main.css'
import { TagsInput } from "react-tag-input-component";


function MyVerticallyCenteredModal(props) {

  console.log(props)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.type} Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form className='row'>
      <BlogUpdate blogdata={props.currentData} />
      {/* <AddNewBlog oldData = {props.currentData} /> */}
    </Form>

      </Modal.Body>

       

      <Modal.Footer>
        <button onClick={props.onHide} className='btn-cms'>Close</button>
      </Modal.Footer>
    </Modal>
  );
}


const AllBlog = () => {

            /* api call */
            const [AllBlogData, setAllBlogData] = useState([]);
            const apiAllBlog =  async () =>{
              let res = await BlogContentGet();
              setAllBlogData(res.data)
            }
            useEffect(()=>{
              apiAllBlog();
            },[])

           
            const apiAllBlogDelete =  async (bID) =>{
              let res = await BlogContentDelete(bID);
              if(res.status===true){
                SweetAlert({title:'Blog Delete' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
              }
              apiAllBlog();
            }
           
 

  const [modalShow, setModalShow] = React.useState(false);
 

  const [BlogData, setBlogData] = useState('');

  let submitBlog = (e)=>{
      e.preventDefault();
  }

  let openModel = (props) =>{
    setModalShow(true)
    setBlogData(props)
  }


  return (
    <Container fluid className="container-fluid ">
  
  <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}

        currentData = {BlogData}

        alldataFn={apiAllBlog}
      />


    <Form action="" className='row' onSubmit={submitBlog}>

    <Col md={12} className="">
    <div className="card-body-w box-s">
      <h6>All Blog list</h6>
      <hr />

      <div className="table-responsive">
      <table className="table bg-white table-hover box-s-n">
        <thead>
          <tr>
            <th> <div style={{'width' : '150px'}} > Title </div></th>
            <th> <div style={{'width' : '150px'}} > Date </div></th>
            <th> <div style={{'width' : '150px'}} > Status </div></th>
            <th> <div style={{'width' : '150px'}} > Action </div></th>
          </tr>
        </thead>

        <tbody>
         {AllBlogData.map((val)=>{
          return(<>
            <tr>
            <td> <div className="cursor-pointer  hov-b-t "> {val.title} </div></td>
            <td> <div className="cursor-pointer"> {val.createdAt.slice(0,10)} </div></td>
            <td> <div className="cursor-pointer"> <button className="btn-tag">{val.state===true ? 'publish' : 'draft'}</button> </div></td>
            <td> <div className='d-flex'><i className="fa-solid fa-pen cursor-pointer i-c hov-g me-1"  onClick={() => openModel(val)} ></i> <i class="fa-regular fa-trash-can cursor-pointer  i-c hov-r" onClick={()=>{apiAllBlogDelete(val._id)}} ></i></div> </td>
          </tr>
          </>)
         }) }


          
        </tbody>
      </table>
      </div>

    </div>
    </Col>


    </Form>

</Container>
  )
}

export default AllBlog;




export const BlogUpdate = (props) =>{

      /* api call */
      const [CategoryData, setCategoryData] = useState([]);
      const apiCategory =  async () =>{
        let res = await BlogCategoryGet();
        setCategoryData(res.data)
      }
      useEffect(()=>{
        apiCategory();
      },[])


      const [Discription, setDiscription] = useState(props.blogdata.discription);
      const [blogState, setblogState] = useState(props.blogdata.state);
      const [selected, setSelected] = useState(props.blogdata.meta_keyword[0].split(","));
      const [blogTags, setblogTags] = useState(props.blogdata.tags[0].split(","));



      const [blogCollect , setblogCollect] = useState({
        title : '',
        category : '',
        tags : '',
        userID : '',
        slug : '',
        meta_title : '',
        meta_keyword : '',
        meta_discription : '',
        blog_image : ''     
       })

      
      const handelBlogInp = (e)=>{
        let name = e.target.name;
        let type = e.target.type;
        let value;
        if(type === 'file') {
          value = e.target.files[0];
        }
        else{
          value = e.target.value;
        }
        setblogCollect({...blogCollect , [name]:value})
      }

      const apiBlogPut =  async (e) =>{
        e.preventDefault();
        let res = await BlogContentPut(blogCollect,Discription,selected,blogTags,blogState,props.blogdata._id );
        
    

        if(res.status===true){
          SweetAlert({title:'Blog Updation' , msg : "success , please refresh if not been reflected" , icon : 'success', btn : 'close', dangerMode: true});
        }
        props.alldataFn();
      }

  


  return(<>
              <Form action="" >

<Col className=" ">


<div className=" my-2">
            <h6>Blog Content</h6>
            <hr />

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Title</label>
              <input type="text" id="" className='form-control rounded-1' name='title'  defaultValue={props.blogdata.title} onChange={handelBlogInp} />
            </div>

     { blogState === true ?   <Form.Check 
        className='cursor-pointer'
        onChange={()=>{setblogState(!blogState)}}
        checked
        type="switch"
        label="Blog Status : Active"
        id="disabled-custom-switch"
      /> : <Form.Check 
        className='cursor-pointer'
        onChange={()=>{setblogState(!blogState)}}
        type="switch"
        label="Blog Status : Draft"
        id="disabled-custom-switch"
      /> }
    

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Category</label>
              <select id="" className="form-select rounded-1" name='category'  onChange={handelBlogInp}>
                <option value="" disabled> -- select --</option>
                
                {
                  CategoryData?.map((val)=>{
                    return(<>
                      {props.blogdata.category ===  val.title ? 
                      <option className='text-capitalize' selected value={val.title}>{val.title}</option> 
                      :
                      <option className='text-capitalize'  value={val.title}>{val.title}</option>}

                    </>)
                  })
                }

              </select>
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Content</label>
              <ReactQuill modules={quill_modules} theme="snow" value={Discription} onChange={setDiscription}  className="rounded-1"   />
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">Tags</label>
              <TagsInput
        value={blogTags}
        onChange={setblogTags}
        name="meta_keyword"
        placeHolder="enter keywords"

      />
            </div>


            <Row className=" ">
    
          <Col md={6}>
            <input type='file' name='blog_image' onChange={handelBlogInp} />
          </Col>

          <Col md={6} className="box">
          <p>Featured Image </p>
          <hr />

            <img src={props.blogdata.image} alt="" className='rounded-2' height='200px' width='200px' />
          </Col>
          </Row>



            <div className="form-group my-3">
             <button className='btn-cms' onClick={(e)=>{apiBlogPut(e)}}>Save</button>
             </div>

          </div>

          <div className=" my-5">
            <h6>Seo Content</h6>
            <hr />

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">meta title</label>
              <input type="text" id="" className='form-control rounded-1' name='meta_title' defaultValue={props.blogdata.meta_title} onChange={handelBlogInp} />
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">meta description</label>
              <input type="text" id="" className='form-control rounded-1' name='meta_discription' defaultValue={props.blogdata.meta_discription} onChange={handelBlogInp} />
            </div>

            <div className="form-group my-3">
              <label htmlFor="" className="label-tag">meta keyword</label>
              <TagsInput
        value={selected}
        onChange={setSelected}
        name="meta_keyword"
        placeHolder="enter keywords"

      />
            </div>
          </div>


{/* <AddNewBlog category={CategoryData} fillValue = {handelBlogInp} rsData={apiBlogPost} /> */}


</Col>

</Form>
  </>)
}